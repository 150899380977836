import type { ExtensionConfig } from '@goto/shell'

const contactCenterSupervisorBaseUrl = '/experiences/cc-supervisor/'

const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-supervisor',
  url: new URL(`${contactCenterSupervisorBaseUrl}@ucc/cc-supervisor-extension.mjs`, location.origin),
  esModule: true,
  prerequisites: {
    ccFlags: 'cc-supervisor',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
