import { getExternalInterface } from '../../services/external-interface'
import { type ExtensionConfig } from '../extension-config'
import { addExtensionWithDependencies } from './utils'

export const filterForCompanion = (extensionConfigs: ExtensionConfig[]) => {
  if (getExternalInterface().isCompanion) {
    const extensions = new Set<string>()

    extensionConfigs.forEach(extensionConfig => {
      if (extensionConfig.integrationConfig?.shouldLoadInCompanion) {
        addExtensionWithDependencies(extensions, extensionConfigs, extensionConfig)
      }
    })

    return extensionConfigs.filter(extensionConfig => extensions.has(extensionConfig.id))
  }

  return extensionConfigs
}
