import { html, LitElement, property } from 'lit-element'
import { repeat } from 'lit-html/directives/repeat'
import { ParsedHotkey } from './hotkey.models'
import hotkeyShortcutStyles from './hotkey-shortcut.styles.scss'

export class GoToHotkeyShortcut extends LitElement {
  static readonly tagName = 'goto-hotkey-shortcut'

  @property({ type: Object }) hotkey: ParsedHotkey = { translatedLabel: '', keys: [], label: '' }

  static get styles() {
    return hotkeyShortcutStyles
  }

  getHotkeyLabelClass() {
    if (this.hotkey?.label === 'or') {
      return 'hotkey-video-focus-label hotkey-label'
    } else {
      return 'hotkey-label'
    }
  }

  render() {
    return html`
      <div class="hotkey-shortcut">
        <chameleon-typography class=${this.getHotkeyLabelClass()} variant="body-large"
          >${this.hotkey?.translatedLabel}</chameleon-typography
        >
        <div class="hotkey-shortcut-keys">
          ${repeat(
            this.hotkey.keys,
            key => key,
            key => html`<chameleon-code inline class="hotkey">${key}</chameleon-code>`,
          )}
        </div>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToHotkeyShortcut.tagName]: GoToHotkeyShortcut
  }
}
