/* istanbul ignore file
Tested in the child components - navigation-rail and navigation-bar
*/
import { state } from 'lit-element'
import { ShellElement } from '../../../common/shell-element'
import { type I18NEvents, I18NNamespace } from '../../../services/i18n'
import { getEventBus } from '../../../services/namespaces'
import { ShellNamespace, type shellEvents } from '../../../services/shell-namespace'
import { type InternalNavigationLink } from '../types'
import { getBadgeNumbersAPI } from '../../../services/badge-numbers'
import { type NavigationLink } from '../../../common'

export class GoToBaseNavigation extends ShellElement {
  @state() navigationItems: InternalNavigationLink[] = []
  private unsubscribeBadgeEvents: (() => void)[] = []

  connectedCallback() {
    super.connectedCallback()
    this.subscribeToRouteChangeEvent()
    this.subscribeToLocaleChangeEvent()
  }

  private subscribeToRouteChangeEvent(): void {
    const { routeChanged } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)
    routeChanged.on(this.rerenderNavrail)
    this.unsubscribeFunctions.push(() => routeChanged.removeListener(this.rerenderNavrail))
  }

  private subscribeToLocaleChangeEvent(): void {
    const { localeChanged } = getEventBus().subscribeTo<typeof I18NNamespace, typeof I18NEvents>(I18NNamespace)
    localeChanged.on(this.rerenderNavrail)
    this.unsubscribeFunctions.push(() => localeChanged.removeListener(this.rerenderNavrail))
  }

  protected subscribeToBadgeEvent(): void {
    this.unsubscribeFromAllBadgeEvents()
    this.navigationItems.map(item => {
      const listener = () => {
        this.rerenderNavrail()
      }
      getBadgeNumbersAPI().addEventListener('change', listener, { id: item.id, showInNavigation: true })
      this.unsubscribeBadgeEvents.push(() => {
        getBadgeNumbersAPI().removeEventListener('change', listener)
      })
    })
  }

  private unsubscribeFromAllBadgeEvents() {
    this.unsubscribeBadgeEvents.forEach(unsubscribe => unsubscribe())
    this.unsubscribeBadgeEvents = []
  }

  private readonly rerenderNavrail = () => {
    this.requestUpdate()
  }

  protected getBadgeCount(navItem: NavigationLink): number {
    return getBadgeNumbersAPI().getBadgeNumberValue({ id: navItem.id, showInNavigation: true })
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    this.unsubscribeFromAllBadgeEvents()
  }
}
