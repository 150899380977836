import type { ExtensionConfig } from '@goto/shell'

const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-resolve',
  url: new URL('/experiences/cc-resolve/@ucc/cc-resolve-extension.mjs', location.origin),
  esModule: true,
  prerequisites: {
    ccFlags: 'cc-agent.chat-queues.conversation-co-browse',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
