import { isProdEnvironment, LocalStorageKeys } from '../../environments'
import {
  getUrlParameterOverride,
  OVERRIDE_EXTENSION_PARAMETER,
  getLocalStorageOverride,
  setLocalStorageExtensionsOverride,
} from '../../services/devtools/override/accessor'
import { initOverrideBanner } from '../../services/devtools/override/display'
import type { GoToExtensionOverrideConfig } from '../../services/devtools/override/models'
import { isGoToExtensionOverrideConfig } from '../../services/devtools/override/validation'
import type { ExtensionConfig } from '../extension-config'

const overrideIsMatching = (override: GoToExtensionOverrideConfig, key: string) =>
  (override.enabled === undefined || override.enabled) && override.id === key

const getMatchingOverride = (
  key: string,
  overrides: readonly GoToExtensionOverrideConfig[],
): GoToExtensionOverrideConfig | undefined => {
  for (const override of overrides) {
    if (overrideIsMatching(override, key)) {
      return override
    }
  }
}

const generateMergedExtensions = (
  extensions: readonly ExtensionConfig[],
  extensionsOverride: readonly GoToExtensionOverrideConfig[],
): readonly ExtensionConfig[] =>
  extensions.map(extension => {
    const override = getMatchingOverride(extension.id, extensionsOverride)
    if (override) {
      try {
        return { ...extension, ...override, url: new URL(override.url) }
      } catch (error) {
        return extension
      }
    }
    return extension
  })

export const getExtensionConfigsWithOverrides = (extensions: readonly ExtensionConfig[]) => {
  if (isProdEnvironment()) {
    return extensions
  }
  const extensionsOverride =
    getUrlParameterOverride(OVERRIDE_EXTENSION_PARAMETER, isGoToExtensionOverrideConfig) ??
    getLocalStorageOverride(LocalStorageKeys.gotoExtensionsOverride, isGoToExtensionOverrideConfig)

  if (extensionsOverride) {
    const mergedExtensions = generateMergedExtensions(extensions, extensionsOverride)
    setLocalStorageExtensionsOverride(extensionsOverride)
    initOverrideBanner()

    return mergedExtensions
  }
  return extensions
}
