import { type ExternalInterface } from './external-interface'
import { DesktopCompanionConnectionStatus, type DesktopCompanionStatusChangedEvent } from '@getgo/container-client'
import { type AppStateMessagePayload } from './messages/app-state'
import { switchAccount } from '../account/events'
import { setUserPreferencesInLocalStorage, updateAuthToken } from './utils'

export const externalInterfaceAppStateListener = (externalInterface: ExternalInterface) => {
  const handleConnectionChanged = (e: DesktopCompanionStatusChangedEvent) => {
    if (e.connectionStatus === DesktopCompanionConnectionStatus.connected) {
      externalInterface.send({
        type: 'app-state',
        payload: {
          topic: 'get-app-state',
        },
      })
    }
  }

  const handleAppState = (payload: AppStateMessagePayload) => {
    if (payload.topic === 'get-app-state-response') {
      const typedPayload = payload as AppStateMessagePayload<'get-app-state-response'>

      updateAuthToken(typedPayload.data.token)
      switchAccount(typedPayload.data.currentAccount)
      setUserPreferencesInLocalStorage(typedPayload.data.userPreferences)
    }
  }

  if (externalInterface.supportsCompanion) {
    externalInterface.addConnectionCallback(handleConnectionChanged)
    externalInterface.addCallback('app-state', handleAppState)
  }
}
