import { type GlobalSearchResultItem, type GlobalSearchActionUI } from '..'
import { getShellAnalytics } from '../../../common/shell-api-helpers'
import {
  stringContainsDigits,
  stringContainsEmail,
  stringContainsLetters,
  stringIsMeetingUrl,
  stringContainsSpecialCharacters,
  stringIsMeetingId,
  stringIsPhoneNumber,
  stringWordCount,
} from '../../../common/helpers/string'
import {
  type AnalyticsResultItemProperty,
  type AnalyticsActionProperty,
  type GlobalSearchAnalyticsProperties,
} from './models'

export const trackGlobalSearch = (analyticsProperties: GlobalSearchAnalyticsProperties) => {
  const { action, eventName, eventType, properties, searchString, searchResult } = analyticsProperties
  const isPhoneNumber = stringIsPhoneNumber(searchString)
  const digits = stringContainsDigits(searchString)
  const letters = stringContainsLetters(searchString)
  const specialCharacters = stringContainsSpecialCharacters(searchString)
  const characterCount = searchString.length
  const wordCount = stringWordCount(searchString)
  const email = stringContainsEmail(searchString)
  const meetingUrl = stringIsMeetingUrl(searchString)
  const isMeetingId = stringIsMeetingId(searchString)
  const actionInfo = action ? getActionProperties(action) : undefined
  const searchResultInfo = searchResult ? getResultItemProperties(searchResult) : undefined
  getShellAnalytics().track(eventName, {
    ...properties,
    eventType,
    isPhoneNumber,
    digits,
    letters,
    specialCharacters,
    characterCount,
    wordCount,
    email,
    meetingUrl,
    isMeetingId,
    actionInfo,
    searchResultInfo,
  })
}

const getResultItemProperties = (searchResult: GlobalSearchResultItem): AnalyticsResultItemProperty => ({
  application: searchResult.application,
  score: searchResult.score,
})

const getActionProperties = (action: GlobalSearchActionUI): AnalyticsActionProperty => ({
  actionID: action.id,
  commandName: action.command.commandName,
  commandNamespace: action.command.namespace,
})
