import {
  getFromLocalStorage,
  getLocationPathname,
  getWindow,
  removeFromLocalStorage,
  setToLocalStorage,
} from '../common/dom-helpers'
import { LocalStorageKeys } from '../environments'
import { isMainWindow } from '../services/container'
import { onWindowUnload } from './helpers/window'
import { navigateToUrl } from './helpers'
import { getShellLogger } from './logger'
import { SHELL_OPTIMIZED_PAGES_ROUTES } from './routes'
import { SINGLE_SPA_ROUTING_EVENT } from './single-spa-events'
import { getEventBus } from '../services/namespaces/event-bus'
import { type shellEvents, ShellNamespace } from '../services/shell-namespace'
import { getExtensionsManager } from '../extensions/extensions-manager'
import { isMainExperienceMounted } from '../experiences/helpers'

export type StoreCurrentRouteOptions = { readonly defaultRoute?: string }

/**
 * Clear the localStorage, store current route if no experience is mounted to prevent the user from being blocked on a wrong path
 * Redirect to the default route instead
 */
export const routingEventListener = (options?: StoreCurrentRouteOptions) => {
  navigateToDefaultRouteIfNothingOnCurrentRoute(options)
}

export const applicationReadyListener = (options?: StoreCurrentRouteOptions) => {
  navigateToDefaultRouteIfNothingOnCurrentRoute(options)
}

const isRouteWithoutExtension = (locationPathname: string) => !!SHELL_OPTIMIZED_PAGES_ROUTES.find(pageRoute => pageRoute === locationPathname)

// The app callback in createAppForExtensionModule is async, therefore an extension could take a while to return a component.
// We verify that the route is a valid shell module route or a route that doesn't need an extension loaded as a fallback
// in this case and, if so, we do not redirect to the default route.
const navigateToDefaultRouteIfNothingOnCurrentRoute = (options?: StoreCurrentRouteOptions) => {
  const locationPathname = getLocationPathname()
  const appIsOnValidRoute =
    isMainExperienceMounted() ||
    getExtensionsManager().isValidShellModuleRoute(locationPathname) ||
    isRouteWithoutExtension(locationPathname)
  if (!appIsOnValidRoute) {
    removeFromLocalStorage(LocalStorageKeys.gotoCurrentRoute)
    const { defaultRoute = '/' } = options ?? {}
    getShellLogger().warn(
      `[URL]: "${location.pathname}" does not have a supported extension. Redirecting to [DEFAULT URL]: "${defaultRoute}"`,
    )
    navigateToUrl(defaultRoute)
  }
}

/**
 * Init the routing event listener
 * This listener is only setup in the main Window to avoid race storage
 */
export const initializeRoutingListeners = (options?: StoreCurrentRouteOptions) => {
  if (!isMainWindow()) {
    return
  }

  const listener: EventListener = () => routingEventListener(options)
  const { applicationReady } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)

  const onApplicationReady = () => {
    applicationReadyListener(options)
  }

  getWindow().addEventListener(SINGLE_SPA_ROUTING_EVENT, listener)
  applicationReady.on(onApplicationReady)

  onWindowUnload(() => {
    getWindow().removeEventListener(SINGLE_SPA_ROUTING_EVENT, listener)
    applicationReady.removeListener(onApplicationReady)
  })
}

/**
 * Get the applicable localStorage store current route
 * @returns string
 */
export const getStoreCurrentRoute = (): string | null => getFromLocalStorage(LocalStorageKeys.gotoCurrentRoute)

/**
 * Set the applicable localStorage, store current route
 * @param gotoCurrentRoute
 */
export const setStoreCurrentRoute = (gotoCurrentRoute: string): void =>
  setToLocalStorage(LocalStorageKeys.gotoCurrentRoute, gotoCurrentRoute)

/**
 * Navigate to the current route saved in localStorage
 */
export const navigateToStoredRoute = () => {
  const storedRoute = getStoreCurrentRoute()
  if (storedRoute) {
    navigateToUrl(storedRoute)
  }
}
