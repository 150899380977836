import { convertToKebabCase } from '../../common/helpers/string'
import { createSettingsHref } from '../../common/settings'
import type { Settings, SettingsDefinition } from '../settings-definition'
import type { ShellModule } from '../shell-module'
import { isSettings } from './type-checking'

/**
 * Walk through settings defintion to get all the settings
 * @param settings
 * @param baseRoute
 * @returns
 */
export const updateSettingsRoutes = (settings: readonly SettingsDefinition[], baseRoute = ''): readonly Settings[] => {
  let flattenSettings: readonly Settings[] = []
  if (!settings || settings.length === 0) {
    return flattenSettings
  }
  settings.forEach(settingsDefinition => {
    if (isSettings(settingsDefinition)) {
      // update the settings route
      const href = settingsDefinition.route
      const updateSetting: Settings = {
        ...settingsDefinition,
        route: createSettingsHref(href, baseRoute),
      }
      flattenSettings = flattenSettings.concat(updateSetting)
    } else {
      // create the deep route
      const settingRoute = `${baseRoute ? `${baseRoute}/` : ''}${convertToKebabCase(settingsDefinition.displayNameKey)}`
      flattenSettings = flattenSettings.concat(updateSettingsRoutes(settingsDefinition.children, settingRoute))
    }
  })

  return flattenSettings
}

/**
 * Transform settings to shellModules
 * @param settings
 * @returns
 */
export const transformSettingsToShellModule = ({ componentId, displayNameKey, route }: Settings): ShellModule =>
  ({
    componentId,
    displayNameKey,
    routes: [route],
  }) as ShellModule
