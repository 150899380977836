import { isMainWindow } from '../../services/container'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import { doLoadKeyboardShortcutConfiguration } from '../../services/keyboard-shortcut/keyboard-shortcut'
import { subscribeToKeyboardShortcutConfigurationChange } from '../../services/keyboard-shortcut/keyboard-shortcut-events'
import type { ShellHook } from './models'
import { Plugins } from '@getgo/container-client'
import { getShellLogger } from '../../common/logger'
import { navigateToUrl } from '../../common'
import { SHELL_SETTINGS_ROUTE } from '../../common/routes'
import { onWindowUnload } from '../../common/helpers/window'

export const initializeKeyboardShortcuts: ShellHook = async () => {
  if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_KEYBOARD_SHORTCUT_EDITING)) {
    subscribeToKeyboardShortcutConfigurationChange()
    Plugins.KeyboardShortcuts.systemInterface.initializeLogger(getShellLogger().create('keyboardShortcuts'))
    if (isMainWindow()) {
      await doLoadKeyboardShortcutConfiguration()
    }
  }
}

/**
 * Subscribe the Settings link with the KeyboardShortcuts plugins only for the Main window
 */
export const subscribeSettingsLinkWithKeyboardShortcuts: ShellHook = async () => {
  if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_KEYBOARD_SHORTCUT_EDITING)) {
    if (isMainWindow()) {
      const unsubscribe = await Plugins.KeyboardShortcuts.subscribe('openSettings', window, () => {
        navigateToUrl(`${SHELL_SETTINGS_ROUTE}`)
      })

      onWindowUnload(async () => {
        await unsubscribe()
      })
    }
  }
}
