import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'

import { getEventBus, eventEmitter } from '../namespaces/event-bus'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { formatPersonName } from '../../directives/person-name'
import { i18nLocales, I18N_LOCALES } from '../../common/translate-helpers/locales'

export type Locale = (typeof i18nLocales)[number]

export const I18NNamespace = 'I18N'

export const I18NEvents = {
  localeChangeBegin: eventEmitter<Locale>(),
  localeChanged: eventEmitter<Locale>(),
}

const { emit } = getEventBus().register(I18NNamespace, I18NEvents)

export const localeChangeBegin = (locale: Locale) => emit.localeChangeBegin(locale)

/**
 * Sets the locale in the document
 * @param locale Locale to set in document
 * @param document Document
 */
export const setDocumentLocale = (locale: Locale, document: Document) => {
  document?.documentElement.setAttribute('lang', formatLocale(locale, '-'))
}

/** Changes the current locale of the Shell and emits the localeChanged event. */
export const setCurrentLocale = (locale: Locale) => {
  globalThis.shell.i18n.selectedLocale = locale
  i18n.changeLanguage(locale)
  emit.localeChanged(locale)
}

export const FALLBACK_LNG = 'en_US'

export interface ShellI18NAPI {
  readonly instance: typeof i18n
  // eslint-disable-next-line functional/prefer-readonly-type
  selectedLocale: Locale
  readonly setCurrentLocale: typeof setCurrentLocale
  readonly formatPersonName: typeof formatPersonName
}

/**
 * Verifies if the specified locale is supported in Shell internationalization system
 * @param locale Locale to verify
 * @returns True if locale is supported by Shell
 */
export const isLocaleSupported = (locale: Locale | string): locale is Locale =>
  i18nLocales.some(shellLocale => locale === shellLocale)

/**
 * Formats the specified locale with the given separator
 * @param locale Locale to format
 * @param separator New separator to use (dash "-" or underscore "_")
 * @returns Formatted locale
 */
export const formatLocale = (locale: string, separator: '-' | '_') => locale.replace(/[-_]/g, separator)

/**
 * Retrieves the list of preferred locales from the current browser
 * @returns browser's locales list
 */
export const getBrowserLocales = (): readonly string[] =>
  navigator.languages.length > 0 ? navigator.languages : [navigator.language]

/**
 * Retrieves the default locale that should be used from the browser
 * @returns Locale to be used by default
 */
export const getDefaultLocale = (): Locale => {
  const browserLocales = getBrowserLocales()
  return (
    (browserLocales
      .map(browserLocale => formatLocale(browserLocale, '_'))
      .find(shellLocale => isLocaleSupported(shellLocale)) as Locale) ?? FALLBACK_LNG
  )
}

export function getShellI18NAPI(locale: Locale | null = null): ShellI18NAPI {
  return {
    instance: i18n,
    selectedLocale: locale ? locale : getDefaultLocale(),
    setCurrentLocale,
    formatPersonName,
  }
}

export const getCurrentLocale = () => getShellApiInstance()?.i18n?.selectedLocale ?? getDefaultLocale()

export type I18nNextInitReturnType = ReturnType<(typeof i18n)['init']>

/**
 * This function returns a locale to be used for translations,
 * based on the supported language files available from our translation team.
 *
 * @param locale for which we want translations
 * @returns the starting locale or the alternative one if specified in the function
 */

export const getLocaleForTranslation = (locale: Locale) => {
  let localeForI18n = locale
  switch (locale) {
    case 'fr_CA':
      localeForI18n = 'fr_FR'
      break
    case 'es_MX':
      localeForI18n = 'es_ES'
  }

  return localeForI18n
}

export const initTranslations = async (locale: Locale, baseUrl?: string): I18nNextInitReturnType =>
  await i18n
    .use(HttpApi)
    .init({
      // https://github.com/i18next/i18next/blob/v19.8.7/index.d.ts#L167
      fallbackLng: FALLBACK_LNG,
      keySeparator: false,
      nsSeparator: false,
      interpolation: {
        escapeValue: false,
      },
      returnEmptyString: false,
      lng: getLocaleForTranslation(locale),
      load: 'currentOnly',
      backend: {
        loadPath: `${baseUrl ?? '/i18n/'}{{lng}}.json`,
      },
      supportedLngs: I18N_LOCALES.slice(),
    })
    .then(fn => {
      emit.localeChanged(locale)
      return fn
    })
