import type { NavigationItem } from '../../extensions'
import { getDefaultRoute } from '../../common/default-route-helper'
import { wrappedIsLoggedIn } from '../auth/wrapper'
import { getActiveUrlPath } from '../../common/dom-helpers'
import { getStoreCurrentRoute, initializeRoutingListeners } from '../../common/store-current-route'
import { handleTosRedirection } from '../terms-of-service'

export const handleDefaultRouteRedirection = async (navigationItems: readonly NavigationItem[]) => {
  const defaultRoute = getDefaultRoute(navigationItems)
  const currentLocation = getActiveUrlPath()

  let storedRoute: string | null = null

  if ((await wrappedIsLoggedIn()) && currentLocation === '/') {
    storedRoute = getStoreCurrentRoute()
  } else if (currentLocation !== '/') {
    storedRoute = currentLocation
  }

  await handleTosRedirection(storedRoute ?? defaultRoute)
  initializeRoutingListeners({ defaultRoute })
}
