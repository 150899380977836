import { assertGlobalSearchCategory, supportsEOM } from '../../extensions/utils'

import type {
  GlobalSearchActionUI,
  GlobalSearchCategoryUI,
  GlobalSearchManager,
  GlobalSearchQueryUI,
  GlobalSearchResultUI,
  GlobalSearchResultItem,
  GlobalSearchResponse,
  GlobalSearchParams,
  GlobalSearchResponseJSON,
} from './global-search-models'
import type {
  GlobalSearchAction,
  GlobalSearchCategoryColumn,
  GlobalSearchContext,
  GlobalSearchEmptyState,
  GlobalSearchQuery,
  GlobalSearchShellIntegration,
  GlobalSearchValueField,
  ShellExtension,
} from '../../extensions'
import { GlobalSearchShellIntegrationSchema } from '../../extensions'
import { getEventBus, isCommandHandled } from '../../services/namespaces'
import { getShellLogger } from '../../common/logger'
import { getLocationPathname, getWindow } from '../../common/dom-helpers'
import { onWindowUnload } from '../../common/helpers/window'
import { SEARCH_ROUTE } from '../../common/routes'
import { type CacheConfig, fetchSearchWithCache, getCurrentSearchQuery, parseResultData } from './helpers'
import { emitCategoriesUpdated, notifyResultPageQueryChange, setupGlobalSearchCommands } from './namespace'
import { getJiveApiBaseUrl } from '../../core/environment'
import { convertObjectToQueryString } from '../../common'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import type { ExtensionInterface } from '../../services/extensions/models'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { HttpResponseError } from '../../http-utils'
import { isAbortError } from '../../http-utils/utils'
import { type ShellExtensionReadyEventPayload, ShellNamespace, type shellEvents } from '../../services/shell-namespace'
import { getExtensionsManager } from '../../extensions/extensions-manager'

export const GLOBAL_SEARCH_CACHE_NAME = 'globalSearch'
export const GLOBAL_SEARCH_CACHE_TTL = 5 * 60 * 1000 //5 minutes in millisecond

class GlobalSearchManagerImpl implements GlobalSearchManager {
  private categoriesMapInstance: Map<string, GlobalSearchCategoryUI> | undefined = undefined
  private categoriesIntegrationsMap: Map<string, ExtensionInterface<GlobalSearchShellIntegration>> | undefined =
    undefined
  private globalSearchIntegrations: ExtensionInterface<GlobalSearchShellIntegration>[] | undefined = undefined
  private abortController: AbortController | undefined = undefined
  private globalSearchHasCategories = false

  constructor() {
    setupGlobalSearchCommands()
  }

  public async initialize() {
    this.addListeners()
    if (!this.globalSearchIntegrations) {
      const extensionIntegrations = await getShellApiInstance().extensions.queryInterface(
        GlobalSearchShellIntegrationSchema,
      )
      this.globalSearchIntegrations = [...extensionIntegrations]
    }
  }

  private readonly addListeners = () => {
    const w = getWindow()
    w.addEventListener('popstate', this.handleWindowPopstate)
    const { extensionReady } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)
    extensionReady.addListener(this.handleExtensionReady)
    onWindowUnload(() => {
      w.removeEventListener('popstate', this.handleWindowPopstate)
      extensionReady.removeListener(this.handleExtensionReady)
    })
  }

  private addGlobalSearchIntegration(extensionIntegration: GlobalSearchShellIntegration, extension: ShellExtension) {
    this.globalSearchIntegrations
      ? this.globalSearchIntegrations.push({ impl: extensionIntegration, extension })
      : (this.globalSearchIntegrations = [{ impl: extensionIntegration, extension }])
  }

  private readonly handleExtensionReady = (extensionReadyPayload: ShellExtensionReadyEventPayload) => {
    const extension = getExtensionsManager().getExtensionById(extensionReadyPayload.extensionId)
    if (extension && supportsEOM(extension) && extension.supportsInterface(GlobalSearchShellIntegrationSchema)) {
      const extensionIntegration = extension.queryInterface(GlobalSearchShellIntegrationSchema)
      this.addGlobalSearchIntegration(extensionIntegration, extension)
      if (extensionIntegration.getGlobalSearchCategories()?.length) {
        // Invalidating the maps as new extensions with categories are loaded, or else the maps may be stale
        this.invalidateCategoriesMap()
        this.invalidateCategoriesIntegrationsMap()
        this.globalSearchHasCategories = true
        emitCategoriesUpdated()
      }
    }
  }

  private readonly invalidateCategoriesMap = () => {
    this.categoriesMapInstance = undefined
  }

  private readonly invalidateCategoriesIntegrationsMap = () => {
    this.categoriesIntegrationsMap = undefined
  }

  public hasCategories = () => this.globalSearchHasCategories

  private readonly handleWindowPopstate = () => {
    if (getLocationPathname().startsWith(SEARCH_ROUTE)) {
      const query = getCurrentSearchQuery()
      notifyResultPageQueryChange({ query })
    }
  }

  private readonly getGlobalSearchIntegrations = (): readonly ExtensionInterface<GlobalSearchShellIntegration>[] =>
    this.globalSearchIntegrations ?? []

  private readonly getGlobalSearchIntegrationByApplication = (application: string) =>
    this.getCategoriesIntegrationsMap()?.get(application)

  public getCategories = () => Array.from(this.getCategoriesMap().values())

  public getCategoryByApplication = (application: string) => this.getCategoriesMap().get(application)

  public getCategory = (result: GlobalSearchResultItem) => this.getCategoryByApplication(result.application)

  public getGlobalSearchResultActions = (searchResult: GlobalSearchResultItem, context: GlobalSearchContext) => {
    const searchResultActionsUI: GlobalSearchActionUI[] = []
    this.getGlobalSearchIntegrations().forEach(integration => {
      integration.impl.getGlobalSearchResultActions(searchResult, context)?.forEach(action => {
        if (this.canAddActionTo(action, searchResultActionsUI)) {
          searchResultActionsUI.push(this.convertActionToUI(action, integration.extension))
        }
      })
    })
    return searchResultActionsUI
  }

  private readonly convertActionToUI = (
    { displayNameKey, ...rest }: GlobalSearchAction,
    extension: ShellExtension,
  ) => ({
    ...rest,
    displayName: extension.getString(displayNameKey),
  })

  private readonly convertResultToGlobalSearchResultUI = (
    result: GlobalSearchResultItem,
    context: GlobalSearchContext,
  ) => {
    const integrations = this.getGlobalSearchIntegrations()
    let uiResult = {
      title: '',
      preview: '',
      actions: [],
      userKey: '',
      givenName: '',
      familyName: '',
      icon: '',
      originalResult: result,
      defaultAction: undefined,
    } as GlobalSearchResultUI
    const extension = this.getGlobalSearchIntegrationByApplication(result.application)?.extension
    const category = this.getCategoryByApplication(result.application)
    if (category) {
      const title = this.getResultFieldValue(result, category.titleField) ?? ''
      const preview = this.getResultFieldValue(result, category.previewField) ?? ''
      const userKey = this.getResultFieldValue(result, category.avatarFields?.userKey) ?? ''
      const givenName = this.getResultFieldValue(result, category.avatarFields?.givenName) ?? ''
      const familyName = this.getResultFieldValue(result, category.avatarFields?.familyName) ?? ''
      const icon = this.getResultFieldValue(result, category.iconField) ?? ''
      const defaultAction = category.getResultDefaultAction(result, context)
      uiResult = {
        ...uiResult,
        title: uiResult.title || title,
        preview: uiResult.preview || preview,
        userKey: uiResult.userKey !== '' ? uiResult.userKey : userKey,
        givenName: uiResult.givenName !== '' ? uiResult.givenName : givenName,
        familyName: uiResult.familyName !== '' ? uiResult.familyName : familyName,
        icon: uiResult.icon !== '' ? uiResult.icon : icon,
        defaultAction: defaultAction ? this.convertActionToUI(defaultAction, extension!) : undefined,
        originalResult: uiResult.originalResult,
      }
    }

    return integrations.reduce((uiResult: GlobalSearchResultUI, integration) => {
      const integrationActions = integration.impl.getGlobalSearchResultActions(result, context)

      if (integrationActions) {
        const handledActions: GlobalSearchActionUI[] = []

        integrationActions.forEach(action => {
          if (this.canAddActionTo(action, uiResult.actions)) {
            handledActions.push(this.convertActionToUI(action, integration.extension))
          }
        })
        uiResult = { ...uiResult, actions: [...uiResult.actions, ...handledActions] }
      }
      return uiResult
    }, uiResult)
  }

  public convertToGlobalSearchResultUI = (
    results: readonly GlobalSearchResultItem[],
    context: GlobalSearchContext,
  ): readonly GlobalSearchResultUI[] =>
    results?.map(result => this.convertResultToGlobalSearchResultUI(result, context))

  public searchFor = async (
    searchQuery: GlobalSearchQueryUI,
    params?: GlobalSearchParams,
  ): Promise<GlobalSearchResponse | undefined> => {
    const searchString = searchQuery.query
    const queries = this.getQueriesForSearchString(searchString)

    // This is a temporary condition to be able to test
    if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_GLOBAL_SEARCH_MOCK_API_RESPONSE)) {
      const mockApi = await import('../../../mock-data/mock-global-search-api')
      const mockResults = await Promise.resolve(mockApi.mockSearchFor(queries, params))
      return mockResults
    }

    if (this.getCategories().length) {
      return await this.queryGlobalSearchAPI(queries, params).then(parseResultData)
    }
    return await Promise.resolve(undefined)
  }

  private readonly handleError = <T>(error: HttpResponseError<T>) => {
    throw new HttpResponseError(error)
  }

  private readonly queryGlobalSearchAPI = async (
    queries: GlobalSearchQuery[],
    params?: GlobalSearchParams,
  ): Promise<GlobalSearchResponseJSON | undefined> => {
    const stringParams = params ? '?' + convertObjectToQueryString(params) : ''
    const searchAPIUrl = `${getJiveApiBaseUrl()}/global-search/v1/search${stringParams}`

    try {
      this.abortPreviousRequest()

      const cacheConfig: CacheConfig = {
        requestKey: stringParams + queries[0].query,
        ttl: GLOBAL_SEARCH_CACHE_TTL,
      }

      const init = {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        credentials: undefined,
        signal: this.abortController?.signal,
        body: JSON.stringify({
          queries,
        }),
      }

      return await fetchSearchWithCache<GlobalSearchResponseJSON>(searchAPIUrl, init, cacheConfig)
    } catch (e) {
      if (isAbortError(e)) {
        //Silently abort
      } else {
        getShellLogger().error(`No search results could be found, ${e}`)
        this.handleError(e as HttpResponseError<unknown>)
      }
    }
  }

  private abortPreviousRequest() {
    if (this.abortController) {
      this.abortController.abort()
    }
    this.abortController = new AbortController()
  }

  public getResultFieldValue = (result: GlobalSearchResultItem, field?: GlobalSearchValueField | undefined) => {
    if (field) {
      if (typeof field === 'string') {
        return result.data[field] as string
      }
      return field(result)
    }
  }

  private readonly createCategoriesMaps = () => {
    this.categoriesIntegrationsMap = new Map()

    const categories = new Map<string, GlobalSearchCategoryUI>()
    this.getGlobalSearchIntegrations().forEach(integration => {
      integration.impl.getGlobalSearchCategories().forEach(category => {
        try {
          assertGlobalSearchCategory(category)
          const columnsUI = category.columns.map(column => this.convertColumnToUI(column, integration.extension))
          const categoryUI = {
            ...category,
            columns: columnsUI,
            emptyState: this.convertEmptyStateToUI(category.emptyState, integration.extension),
          }
          categories.set(category.application, categoryUI)
          this.categoriesIntegrationsMap?.set(category.application, integration)
        } catch (e) {
          getShellLogger().error(e)
        }
      })
    })
    this.categoriesMapInstance = categories
  }

  private readonly getCategoriesIntegrationsMap = () => {
    if (!this.categoriesIntegrationsMap) {
      this.createCategoriesMaps()
    }
    return this.categoriesIntegrationsMap
  }

  private readonly getCategoriesMap = () => {
    if (!this.categoriesMapInstance) {
      this.createCategoriesMaps()
    }
    return this.categoriesMapInstance || new Map()
  }

  private readonly convertColumnToUI = (
    { displayNameKey, ...rest }: GlobalSearchCategoryColumn,
    extension: ShellExtension,
  ) => ({
    ...rest,
    displayName: extension.getString(displayNameKey),
  })

  private readonly convertEmptyStateToUI = (
    { messageKey, actions }: GlobalSearchEmptyState,
    extension: ShellExtension,
  ) => ({
    message: extension.getString(messageKey),
    actions: actions.map(action => this.convertActionToUI(action, extension)),
  })

  private canAddActionTo(
    action: GlobalSearchAction,
    actionList: GlobalSearchActionUI[] | readonly GlobalSearchActionUI[],
  ): boolean {
    return isCommandHandled(action.command) && !actionList.find(existingAction => existingAction.id === action.id)
  }

  public getActionsForSearchString = (
    searchString: string,
    context: GlobalSearchContext,
  ): readonly GlobalSearchActionUI[] | undefined => {
    const integrations = this.getGlobalSearchIntegrations()
    const result: GlobalSearchActionUI[] = integrations.reduce((actions: GlobalSearchActionUI[], integration) => {
      const integrationActions = integration.impl.getActionsForSearchString(searchString, context)
      if (integrationActions) {
        integrationActions.forEach(action => {
          if (this.canAddActionTo(action, actions)) {
            actions.push(this.convertActionToUI(action, integration.extension))
          }
        })
      }
      return actions
    }, [] as GlobalSearchActionUI[])

    return result
  }

  public getQueriesForSearchString = (searchString: string): GlobalSearchQuery[] => {
    const categories = this.getCategories()
    return categories.map(category => category.getQueryForSearchString(searchString)).filter(query => query)
  }
}
let globalSearchManagerInstance: GlobalSearchManager

export const getGlobalSearchManager = (): GlobalSearchManager => {
  if (!globalSearchManagerInstance) {
    globalSearchManagerInstance = new GlobalSearchManagerImpl()
  }
  return globalSearchManagerInstance
}
