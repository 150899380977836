import { LitElement, html, property } from 'lit-element'
import contextMenuStyles from './context-menu.styles.scss'
import { repeat } from 'lit-html/directives/repeat'
import type { ContextMenu } from './models'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { nothing } from 'lit-html'
import { t } from '../../directives/translate'
import { navigateToUrl } from '../../common/helpers'
import { openNewTabFor } from '../../common/dom-helpers'
import { ifDefined } from 'lit-html/directives/if-defined'
import { getShellAnalytics } from '../../common/shell-api-helpers'

export class GoToContextMenu extends LitElement {
  static readonly tagName = 'goto-context-menu'

  @property({ type: Array })
  menuItems: readonly ContextMenu[] = []

  static get styles() {
    return contextMenuStyles
  }

  private renderMenuItem(contextMenu: ContextMenu) {
    const handleClick = (contextMenu: ContextMenu) => {
      getShellAnalytics().track(`GoTo > ${contextMenu.origin}`, { action: 'click', event: contextMenu.text })
      contextMenu.isExternalLink ? openNewTabFor(contextMenu.href) : navigateToUrl(contextMenu.href)
    }
    return html`
      <chameleon-menu-item
        @click=${() => handleClick(contextMenu)}
        aria-label=${contextMenu.text}
        data-test=${ifDefined(contextMenu.dataTest)}
      >
        ${contextMenu.icon
          ? html`<chameleon-svg slot="leading-icon">${unsafeSVG(contextMenu.icon)}</chameleon-svg>`
          : nothing}
        ${contextMenu.text}
      </chameleon-menu-item>
    `
  }

  render() {
    return html`
      <nav>
        <chameleon-menu label=${t('GoTo menu')}>
          ${repeat(this.menuItems, menuItem => menuItem.href, this.renderMenuItem)}
        </chameleon-menu>
      </nav>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToContextMenu.tagName]: GoToContextMenu
  }
}
