import type { ExtensionConfig } from '@goto/shell'
import { getFeatureFlag } from '../helpers/shell-helpers'

const contactCenterAgentBaseUrl = '/experiences/cc-agent'

const CCE_AGENT_LOAD_EXTENSION_PREREQUISITES_FLAG = 'cce-agent-load-extension-prerequisites'

export const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-agent/extension',
  url: new URL(`${contactCenterAgentBaseUrl}/extension.js`, location.origin),
  prerequisites: {
    get ccFlags() {
      return getFeatureFlag(CCE_AGENT_LOAD_EXTENSION_PREREQUISITES_FLAG, false) ? 'cc-agent.portal' : 'cc-agent'
    },
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
