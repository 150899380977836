import { loadScript } from '../../common'
import { getShellLogger } from '../../common/logger'
import type { ExtensionConfig } from '../extension-config'
import type { ShellExtension } from '../shell-extension'
import { assertShellExtension } from './type-checking'
import { measureLoadingTime } from '../../core/performance'

const EXTENSION_CONFIG_SETUP = 'loadExtensionScript'
const EXTENSION_CONFIG_START_MARK = `${EXTENSION_CONFIG_SETUP} start`
const EXTENSION_CONFIG_DONE_MARK = `${EXTENSION_CONFIG_SETUP} done`

const getExtensionConfigLoadingTimeMarkers = (extensionId: string) => ({
  measure: `shell_${extensionId}_${EXTENSION_CONFIG_SETUP}`,
  startMarker: `shell_${extensionId}_${EXTENSION_CONFIG_START_MARK}`,
  doneMarker: `shell_${extensionId}_${EXTENSION_CONFIG_DONE_MARK}`,
})

/**
 * Loads an extension script
 * @param config extension configuration object
 * @returns instance of loaded extension or null if it fails or if it's invalid
 */
export const loadExtensionScript = async (config: ExtensionConfig): Promise<ShellExtension | null> => {
  try {
    const { measure, startMarker, doneMarker } = getExtensionConfigLoadingTimeMarkers(config.id)
    performance.mark(startMarker)
    const script = await loadScript<any>(config.url.href, { resolveWithGlobal: config.id, esModule: config.esModule })
    performance.mark(doneMarker)
    measureLoadingTime(measure, startMarker, doneMarker)()
    const extension = script.default || script
    try {
      assertShellExtension(extension)
      return extension
    } catch (e) {
      getShellLogger().error(e)
      return null
    }
  } catch (e) {
    getShellLogger().error(config.id, 'extension failed to load', e)
    return null
  }
}
