import { html, LitElement, state } from 'lit-element'
import updateAppAvatarMenuItem from './update-app-avatar-menu-item.styles.scss'
import { t } from '../../../directives/translate/translate'
import { isKeyboardEventFor, noop } from '../../../common'
import type { MenuItemClickEvent } from '@getgo/chameleon-web'
import { getShellApiInstance } from '../../../common/shell-api-helpers'
import type { UpdateEvents } from '../../../services/update'
import { updateAvailable, UpdateNamespace } from '../../../services/update'
import type { Listener } from '../../../services/namespaces'
import type { UpdateInfo } from '@getgo/container-client'
import { differenceInDays } from 'date-fns'
import { nothing } from 'lit-html'
import {
  areShellUpdatesEnabled,
  checkForUpdates,
  isCheckForUpdateInProgress,
  displayUpdateConfirmModal,
  displayUpdateErrorModal,
  displayUpToDateSnackbar,
  getAutoUpdate,
} from '../../../services/update/helpers'

export class GoToAvatarUpdateMenuItem extends LitElement {
  static readonly tagName = 'goto-avatar-update-menu-item'
  @state() private updateAge: number | undefined
  @state() private updateInProgress = false
  @state() private shellUpdatesEnabled = false
  private unsubscribeUpdateAvailable = noop

  static get styles() {
    return updateAppAvatarMenuItem
  }

  async connectedCallback() {
    super.connectedCallback()
    if (await areShellUpdatesEnabled()) {
      this.shellUpdatesEnabled = true
      this.listenToUpdateAvailableEvent()
    }
  }

  disconnectedCallback() {
    this.unsubscribeUpdateAvailable()
    this.unsubscribeUpdateAvailable = noop
    super.disconnectedCallback()
  }

  private listenToUpdateAvailableEvent() {
    const { eventBus } = getShellApiInstance()
    const { updateAvailable, checkForUpdatesStartedOrStopped } = eventBus.subscribeTo<
      typeof UpdateNamespace,
      typeof UpdateEvents
    >(UpdateNamespace)

    const updateAvailableHandler: Listener<UpdateInfo> = (updateInfo: UpdateInfo) => {
      const releaseDate = Date.parse(updateInfo.releaseDate)
      const currentDate = Date.now()
      this.updateAge = differenceInDays(currentDate, releaseDate)
    }

    const checkForUpdatesStartedOrStoppedHandler: Listener<boolean> = (inProgress: boolean) => {
      this.updateInProgress = inProgress
    }

    updateAvailable.on(updateAvailableHandler)
    checkForUpdatesStartedOrStopped.on(checkForUpdatesStartedOrStoppedHandler)

    this.updateInProgress = isCheckForUpdateInProgress()

    this.unsubscribeUpdateAvailable = () => {
      updateAvailable.removeListener(updateAvailableHandler)
      checkForUpdatesStartedOrStopped.removeListener(checkForUpdatesStartedOrStoppedHandler)
    }

    const update = getAutoUpdate()
    if (update) {
      updateAvailableHandler(update)
    }
  }

  private renderMenuItem() {
    return html`
      <chameleon-menu-item
        data-test="update-button"
        @click=${displayUpdateConfirmModal}
        @keydown=${(e: KeyboardEvent) => {
          if (isKeyboardEventFor(e, ['Enter', 'NumpadEnter'], 'keydown')) {
            displayUpdateConfirmModal()
          }
        }}
      >
        ${t('Update GoTo app')}
        <goto-update-icon .updateAge=${this.updateAge as number} slot="end"></goto-update-icon>
      </chameleon-menu-item>
    `
  }

  private handleupdateAvailable(update: UpdateInfo) {
    updateAvailable(update)
    displayUpdateConfirmModal()
  }

  private triggerCheck(event: Event) {
    event.preventDefault()
    checkForUpdates(this.handleupdateAvailable, displayUpToDateSnackbar, displayUpdateErrorModal)
  }

  private renderCheckForUpdatesButton() {
    return html`<chameleon-menu-item
      data-test="check-button"
      @click=${(event: MenuItemClickEvent) => {
        this.triggerCheck(event)
      }}
      @keydown=${(event: KeyboardEvent) => {
        if (isKeyboardEventFor(event, ['Enter', 'NumpadEnter'], 'keydown')) {
          this.triggerCheck(event)
        }
      }}
    >
      ${t('Check for updates')}</chameleon-menu-item
    >`
  }

  private renderCheckingForUpdatesButton() {
    return html`<chameleon-menu-item class="with-spinner" disabled data-test="checking-button"
      >${t('Checking for updates...')}
      <div slot="end" class="update-spinner"></div>
    </chameleon-menu-item>`
  }

  render() {
    if (!this.shellUpdatesEnabled) {
      return nothing
    }

    return this.updateAge !== undefined
      ? this.renderMenuItem()
      : this.updateInProgress
        ? this.renderCheckingForUpdatesButton()
        : this.renderCheckForUpdatesButton()
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToAvatarUpdateMenuItem.tagName]: GoToAvatarUpdateMenuItem
  }
}
