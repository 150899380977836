import type { ExtensionConfig } from '@goto/shell'

const analyticsBaseUrl = '/experiences/analytics/'

const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-analytics',
  url: new URL(`${analyticsBaseUrl}@ucc/cc-analytics-extension.mjs`, location.origin),
  esModule: true,
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
  standaloneRoutes: ['/analytics']
}

export default extensionConfig
