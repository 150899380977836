import { Token } from '@getgo/auth-client'
import { environment } from '../../environments'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getRefreshToken, removeRefreshToken, setRefreshToken, setToken } from './token'
import {
  canQueryNewRefreshToken,
  getRefreshTokenCounter,
  getRefreshTokenLimit,
  incrementRefeshTokensCount,
} from './utils'
import { setInterval, clearInterval } from '../../common/dom-helpers'
import { getTranslation } from '../i18n/i18nUtils'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import { isLoggedIn } from './serverIsLoggedIn'
import { loginOnce, login } from './authentication'
import { canReloadApplication } from './can-reload'
import { fetchRetry } from '../../common/fetch-utils'
import { getShellLogger } from '../../common/logger'
import { HttpResponseError } from '../../http-utils'
import { isBackendReachable } from '../../common/networkUtils'
import stackLogger from '../stack-logger'
import { isContainer } from '../container/helpers'

let isRefreshing = false
let refreshPromise: Promise<void>
// temporary solution until big refactor @askMichel @askJonathan
let tokenRefreshIntervalID: number | undefined

const sessionExpiredMessage = {
  modalTitle: asTranslationKey('Your session expired'),
  modalContent: asTranslationKey('You were inactive a while. To continue where you left off, refresh your session.'),
  modalSignOutActionLabel: asTranslationKey('Sign out'),
  modalRefreshActionLabel: asTranslationKey('Refresh session'),
}

// Idempotent function to handle multiple requests to refreshAuth
export const refreshAuthentication = (): Promise<void> => {
  if (!isRefreshing) {
    isRefreshing = true
    refreshPromise = refreshAuth().then(() => {
      isRefreshing = false
    })
  }
  return refreshPromise
}

/**
 * PKCE:
 * We can refresh at 60% of the token's lifespan.
 * token.expires is the expiration date
 * token.expires_in is the delay between issuance and expiration.
 *
 * Implicit flow:
 * We can refresh 2 hours before expiration -> see jiveweb's code:
 * https://github.com/jive/jiveweb/blob/2.376.0/packages/gtc/src/app/utils/auth-utils.ts#L5
 */
const calculateTokenRefreshTime = (token: Token): number => {
  const { issued, expires } = token as Required<Token>
  return issued + (expires - issued) * 0.6
}

const stopTokenRefreshInterval = (tokenRefreshIntervalID: number | undefined) => {
  if (tokenRefreshIntervalID) {
    clearInterval(tokenRefreshIntervalID)
    tokenRefreshIntervalID = undefined
  }
}

export const refreshTokenInterval = (token: Token) => {
  const tokenRefreshTime = calculateTokenRefreshTime(token)

  stopTokenRefreshInterval(tokenRefreshIntervalID)

  const startTokenRefreshInterval = () => {
    tokenRefreshIntervalID = setInterval(() => {
      const currentTime = Date.now()
      if (currentTime > tokenRefreshTime) {
        stopTokenRefreshInterval(tokenRefreshIntervalID)
        isBackendReachable().then(reachable => {
          if (reachable) {
            stackLogger.addEvent('refreshTokenInterval: backend reachable') // NOSONAR
            refreshAuthentication()
          } else {
            stackLogger.addEvent('refreshTokenInterval: Error backend not reachable') // NOSONAR
            startTokenRefreshInterval()
          }
        })
      }
    }, 5000)
  }

  startTokenRefreshInterval()
}

/**
 * When we log in on https://authentication.logmeininc.com/, we get a cookie.
 * That cookie is used to fetch the tokens.
 *
 * On the web version, multiple apps use the same identity system,
 * hence the need to check if the user is still logged in (using mentioned cookie).
 *
 * In the Container, there's no such concern. The goal is to have the Container
 * not rely on the cookie to persists once logged in, but only on the tokens.
 * The relevant cases are managed in refreshPKCETokens()
 */
const refreshAuth = async () => {
  const isContainerValue = isContainer()
  const getRefreshTokenCounterValue = getRefreshTokenCounter()
  const getRefreshTokenLimitValue = getRefreshTokenLimit()
  stackLogger.addEvent(
    `refreshAuth: isContainer:${isContainerValue} , ${getRefreshTokenCounterValue} out of ${getRefreshTokenLimitValue}`,
  ) // NOSONAR

  if ((isContainer() || (await isLoggedIn())) && canQueryNewRefreshToken()) {
    return await refreshPKCETokens()
  } else {
    stackLogger.addEvent('refreshAuth: refreshLogin') // NOSONAR
    return await refreshLogin()
  }
}

const refreshPKCETokens = async () => {
  const refresh_token = getRefreshToken()
  if (!refresh_token) {
    stackLogger.addEvent('refreshPKCETokens: refreshLogin') // NOSONAR
    return await refreshLogin()
  }
  try {
    const { authUrl, authClientId } = environment()
    const body = new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: authClientId,
      refresh_token,
    })

    const response = await fetchRetry(`${authUrl}/oauth/token`, {
      method: 'POST',
      body,
      retry: {
        maxRetryNumber: 3,
        exponential: true,
        randomized: true,
        retryAfterInMilliseconds: 1000,
        /**
         * error is only defined when the fetch rejects, which should only happen when
         * "only reject on network failure or if anything prevented the request from completing."
         */
        retryOn: async ({ value, error }) => !!error || (value.status >= 400 && value.status !== 401),
      },
    })

    const { status, statusText } = response

    if (status >= 400 && status <= 500) {
      stackLogger.addEvent(`refreshPKCETokens: Error response ${status}`) // NOSONAR
      throw new HttpResponseError({ message: statusText, response, status, statusText })
    }
    const token = new Token(await response.json())
    if (token.refresh_token) {
      setRefreshToken(token.refresh_token)
      delete token.refresh_token
    }
    setToken(token)
    refreshTokenInterval(token)
    incrementRefeshTokensCount()
  } catch (error) {
    getShellLogger().error('shell encountered an error while refreshing the token', error)
    const displayApi = getShellApiInstance()?.display

    if (displayApi) {
      const refreshModal = displayApi.modal({
        /**
         * Reminder; `target` will make sure its only displayed on the main window.
         * That is because this particular refreshPKCETokens is called from and
         * wrapped, and executed only from getShellApiInstance that is
         * always run from the main window.
         *
         * https://jira.ops.expertcity.com/browse/SCORE-1291
         */
        target: window,
        title: getTranslation(sessionExpiredMessage.modalTitle),
        actions: [
          {
            label: getTranslation(sessionExpiredMessage.modalRefreshActionLabel),
            handler() {
              refreshModal.close()
              login()
            },
          },

          {
            label: getTranslation(sessionExpiredMessage.modalSignOutActionLabel),
            variant: 'neutral',
            handler() {
              refreshModal.close()
              getShellApiInstance().auth.logout()
            },
          },
        ],
        id: 'shellSessionExpiredModal',
        content: getTranslation(sessionExpiredMessage.modalContent),
      })
      stackLogger.addEvent('refreshPKCETokens: displayAPI available, modal displayed') // NOSONAR
    } else {
      // if we don't have shell then we can safely redirect
      stackLogger.addEvent('refreshPKCETokens: Error displayAPI not available') // NOSONAR
      await refreshLogin()
    }
  }
}

export const refreshLogin = () => canReloadApplication().then(tryContinueToLogin)

const tryContinueToLogin = (canContinueToLogin: boolean) => {
  if (canContinueToLogin) {
    removeRefreshToken()
    return loginOnce()
  } else {
    stackLogger.addEvent(`tryContinueToLogin: canContinueToLogin ${canContinueToLogin}`) // NOSONAR
  }
}
