import { autoResolve, type NavigationLink } from '../../../common'
import { getExtensionsCacheFromLocalStorage } from '../../../extensions/utils'
import { type NavigationItemsService } from './model'

class NavigationItemsServiceImpl implements NavigationItemsService {
  private navigationOrder: string[] = []

  private sortNavigationLinks(items: NavigationLink[]): NavigationLink[] {
    const unknownItemIndex = this.navigationOrder.length
    const compareItems = (item1: NavigationLink, item2: NavigationLink): number => {
      let index1 = this.navigationOrder.indexOf(item1.id)
      index1 = index1 === -1 ? unknownItemIndex : index1
      let index2 = this.navigationOrder.indexOf(item2.id)
      index2 = index2 === -1 ? unknownItemIndex : index2
      return index1 - index2
    }
    return items.sort(compareItems)
  }

  public getNavigationOrder(): string[] {
    return this.navigationOrder
  }

  public setNavigationOrder(orderedIds: string[]) {
    this.navigationOrder = orderedIds
  }

  // We convert NavigationItemCache -> NavigationLink.
  // Navigation link is an old interface we need to remove in the future
  // Remove this function to convert into NavigationLink and return array of NavigationItem instead
  // JIRA: https://jira.ops.expertcity.com/browse/SCORE-3248
  // @returns a list of NavigationLink
  public getNavigationItems(): NavigationLink[] {
    const cacheNav: NavigationLink[] = []
    const extensionsCache = getExtensionsCacheFromLocalStorage()
    if (extensionsCache?.length) {
      extensionsCache.forEach(extension => {
        if (extension.navigationItems?.length) {
          extension.navigationItems.forEach(item => {
            cacheNav.push({
              ...item,
              isVisible: autoResolve(true),
              href: item.pathname,
              name: item.displayName,
            })
          })
        }
      })
    }
    return this.sortNavigationLinks([...cacheNav])
  }
}

const navigationItemsServiceInstance = new NavigationItemsServiceImpl()

export const getNavigationItemsService = (): NavigationItemsService => navigationItemsServiceInstance
