import { ExtensionConfig, PrerequisiteRuleTypes } from '@goto/shell'
import { getFeatureFlag } from '../helpers/shell-helpers'

enum Flags {
  CONTACTS_VERSIONS = 'contacts-team-app-version',
}

const extensionConfig: ExtensionConfig = {
  id: '@goto/contacts-ui-extension',
  get url() {
    const version = getFeatureFlag(Flags.CONTACTS_VERSIONS, { extension: 'latest' })?.extension
    return new URL(`/experiences/contacts/${version}/extension/index.js`, location.origin)
  },
  prerequisites: {
    [PrerequisiteRuleTypes.ENTITLEMENTS]: 'jive || gotoconnect',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
