import { html, LitElement, property } from 'lit-element'
import hotkeySectionStyles from './hotkey-section.styles.scss'
import type { Hotkey, ParsedHotkey } from './hotkey.models'
import { isMac } from '../../services/container/helpers'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { repeat } from 'lit-html/directives/repeat'

export class GoToHotkeySection extends LitElement {
  static readonly tagName = 'goto-hotkey-section'

  @property({ type: String }) hotkeySectionLabel = ''
  @property({ type: Array }) hotkeySectionList: Hotkey[] = []

  static get styles() {
    return hotkeySectionStyles
  }

  private getHotkey(hotkey: Hotkey): ParsedHotkey {
    return {
      translatedLabel: getTranslation(hotkey.label),
      keys: this.getKeysBasedOnOS(hotkey),
      label: hotkey.label,
    }
  }

  private getKeysBasedOnOS(hotkey: Hotkey) {
    return isMac() ? hotkey.macOSKey : hotkey.otherOSKey
  }

  render() {
    return html`
      <div class="hotkey-section">
        <chameleon-typography class="hotkey-section-label" tag="h3" variant="heading-small"
          >${this.hotkeySectionLabel}</chameleon-typography
        >
        <ul class="hotkey-section-list">
          ${repeat(
            this.hotkeySectionList,
            hotkey => hotkey.label,
            hotkey =>
              html`<li>
                <goto-hotkey-shortcut .hotkey=${this.getHotkey(hotkey)}></goto-hotkey-shortcut>
              </li>`,
          )}
        </ul>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToHotkeySection.tagName]: GoToHotkeySection
  }
}
