import { state } from 'lit-element'
import { type GoToNavigationProps, type InternalNavigationLink } from '../types'
import { GoToBaseNavigation } from './base-navigation'
import { getEventBus } from '../../../services/namespaces'
import { type NavigationEvents, NavigationNamespace } from '../../../services/navigation/event-bus'
import { getNavigationItemsService } from '../navigation-item/navigation-items-service'
import { type NavigationItemsService } from '../navigation-item/model'

export abstract class GotoBaseNavigationLinks extends GoToBaseNavigation implements GoToNavigationProps {
  @state() links: readonly InternalNavigationLink[] = []

  protected readonly navigationItemsService: NavigationItemsService

  constructor() {
    super()
    this.navigationItemsService = getNavigationItemsService()
  }

  connectedCallback() {
    super.connectedCallback()
    this.setNavLinks()

    const { linksUpdated } = getEventBus().subscribeTo<typeof NavigationNamespace, typeof NavigationEvents>(
      NavigationNamespace,
    )

    linksUpdated.on(this.handleLinksUpdated)

    this.unsubscribeFunctions.push(() => {
      linksUpdated.removeListener(this.handleLinksUpdated)
    })
  }

  abstract linksUpdated(): Promise<void>

  private setNavLinks() {
    this.links = this.navigationItemsService.getNavigationItems()
    this.linksUpdated().then(() => this.requestUpdate())
  }

  private readonly handleLinksUpdated = async () => {
    this.setNavLinks()
  }
}
