import type { ExtensionConfig } from '@goto/shell'

import { getOOSArtifactUrl } from '../helpers/experience-helpers'

import { getFeatureFlag, getLaunchDarklyFlag } from '../helpers/shell-helpers'
import { LaunchDarklyVariations, UnleashFlags } from '../models/feature-flag.models'
import { createIntegrationConfigIfFlagEnabled } from '../helpers/integration-helpers'

const integrationFlagSuffix = 'oos-meeting'

const getExtensionVersion = () => {
  const useUnleash = getFeatureFlag(UnleashFlags.OOS_USE_UNLEASH, false)

  return useUnleash
    ? getFeatureFlag(UnleashFlags.OOS_MEETING_APP_VERSION, 'release')
    : getLaunchDarklyFlag(LaunchDarklyVariations.OOS_MEETING_APP_VERSION)
}

const extensionConfig: ExtensionConfig = {
  id: '@goto/oos-meeting-app',
  get url() {
    const version = getExtensionVersion()
    return new URL(getOOSArtifactUrl('meeting-extension', version))
  },
  get integrationConfig() {
    return createIntegrationConfigIfFlagEnabled(integrationFlagSuffix)
  },
}

export default extensionConfig
