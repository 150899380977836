import type { Application, AppProps, CustomProps, LifeCycles, RegisterApplicationConfig } from 'single-spa'
import { waitForElement } from '../common/dom-helpers'
import { waitForNextEventCycle } from './helpers'
import { SHELL_SETTINGS_ROUTE } from './routes'

/**
 * Create the setting href use to navigate to the setting page
 * Shell settings are under a base path that must be prepend (SHELL_SETTINGS_ROUTE) in addition with an optinal section name
 * @param href
 * @param baseRoute
 * @returns
 */
export const createSettingsHref = (href: string, baseRoute?: string) => {
  const cleanHref = href.replace(/^\/+/gm, '')
  const shellSettingHref = baseRoute
    ? `${SHELL_SETTINGS_ROUTE}/${baseRoute}/${cleanHref}`
    : `${SHELL_SETTINGS_ROUTE}/${cleanHref}`
  return shellSettingHref
}

function isFunction(functionToCheck: unknown): functionToCheck is CallableFunction {
  return typeof functionToCheck === 'function'
}

export const waitForSettingsContainer = async () => {
  await waitForElement('goto-settings', document.body)
  await waitForNextEventCycle()
}

export type LifeCyclePromise = (config: AppProps) => Promise<LifeCycles<AppProps>>

/**
 * Helper to create the mount lifecycle with waiting handler for settings container
 * @param lifecycles
 * @returns
 */
const createMountLifecycle = (lifecycles: LifeCycles) => async (props: AppProps) => {
  await waitForSettingsContainer()
  // The below function call is to wait for the next render cycle to ensure all of the settings dom is fully mounted
  await waitForNextEventCycle()
  const { mount } = lifecycles
  if (Array.isArray(mount)) {
    Promise.all([mount.map(m => m(props))])
  } else {
    mount(props)
  }
}

/**
 * Create lifecycle resolver for single spa application
 * @param app
 * @returns
 */
export const createLifeCycleResolver =
  (app: Application): LifeCyclePromise =>
  async (config: AppProps) => {
    if (isFunction(app)) {
      return await app(config).then(lifecycles => ({
        ...lifecycles,
        mount: createMountLifecycle(lifecycles),
      }))
    }
    return {
      ...app,
      mount: createMountLifecycle(app),
    }
  }

export interface ShellSettingExpRegisterApplicationConfig<T extends CustomProps> extends RegisterApplicationConfig<T> {
  readonly test?: string
}
