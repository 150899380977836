export interface UnleashResponse {
  readonly toggles: readonly UnleashToggle[]
}

export interface UnleashToggle {
  readonly enabled: boolean
  readonly impressionData: boolean
  readonly name: string
  readonly variant: {
    readonly enabled: boolean
    readonly name: string
    readonly payload?: {
      readonly type: UnleashPayloadType
      readonly value: string
    }
  }
}

export enum UnleashPayloadType {
  STRING = 'string',
  JSON = 'json',
}

// eslint-disable-next-line functional/prefer-readonly-type
export type FeatureFlags = { [key: string]: unknown }

export enum LaunchDarklyVariations {
  PKCE_REFRESH_TOKENS = 'pkce-refresh-tokens',
  SHELL_ENABLE_DARKMODE = 'shell-enable-darkmode',
  BRANDING = 'branding-variations',
  SHELL_APPLICATION_SWITCHER = 'shell-application-switcher',
  SHELL_NOTIFICATION_SETTINGS = 'shell-notification-settings',
  SHELL_AVATAR_POPOVER_HELP_MENU_ITEM = 'shell-avatar-popover-help-menu-item',
  SHELL_HOTKEY_SETTINGS = 'shell-hotkey-settings',
  SHELL_LANGUAGE_SETTINGS = 'shell-language-settings',
  SHELL_ADVANCED_PRESENCE_BUSY = 'shell-advanced-presence-busy',
  SHELL_TOPBAR_ACCOUNT_SWITCHER = 'shell-topbar-account-switcher',
  SHELL_RESOLVE_USER_ACCESS_ATTACHMENT = 'shell-resolve-user-access-attachment',
  SHELL_NOTIFICATION_CHANNEL_V2 = 'shell-notification-channel-v2',
  SHELL_TRACK_NC_DISCONNECT_BANNER = 'shell-track-nc-disconnect-banner',
  SHELL_DOCK_BADGE = 'shell-dock-badge',
  SHELL_APP_UPDATE_INTERVAL_OVERRIDE = 'shell-app-update-interval-override',
  SHELL_KEYBOARD_SHORTCUT_EDITING = 'shell-keyboard-shortcut-editing',
  SHELL_PAGES_OPTIMIZATION = 'shell-pages-optimization',
  SHELL_GLOBAL_SEARCH_MOCK_API_RESPONSE = 'shell-global-search-mock-api-response',
  CONTACTS_TEAM_FILTER_SHARED = 'contacts-team-filter-shared',
  CONTACTS_TEAM_USE_TMP_BFF = 'contacts-team-use-tmp-bff',
  CONTACTS_TEAM_API = 'contacts-team-api',
  VOICE_REST_PHONE = 'gtc-voice-rest-phone',
  VOICE_MULTILINE_REGISTRATION = 'gtc-voice-multiline-registration',
}

export enum FeatureFlagsVariations {
  PKCE_REFRESH_TOKENS = 'pkce-refresh-tokens',
  SHELL_ENABLE_DARKMODE = 'shell-enable-darkmode',
  SHELL_APPLICATION_SWITCHER = 'shell-application-switcher',
  SHELL_NOTIFICATION_SETTINGS = 'shell-notification-settings',
  SHELL_AVATAR_POPOVER_HELP_MENU_ITEM = 'shell-avatar-popover-help-menu-item',
  SHELL_HOTKEY_SETTINGS = 'shell-hotkey-settings',
  SHELL_LANGUAGE_SETTINGS = 'shell-language-settings',
  SHELL_ADVANCED_PRESENCE_BUSY = 'shell-advanced-presence-busy',
  // This flag is used in the code but was never created in either LD nor Unleash. If we want to use it, it will need to be created in Unleash.
  SHELL_RESOLVE_USER_ACCESS_ATTACHMENT = 'shell-resolve-user-access-attachment',
  SHELL_NOTIFICATION_CHANNEL_V2 = 'shell-notification-channel-v2',
  SHELL_TRACK_NC_DISCONNECT_BANNER = 'shell-track-nc-disconnect-banner',
  SHELL_APP_UPDATE_INTERVAL_OVERRIDE = 'shell-app-update-interval-override',
  SHELL_KEYBOARD_SHORTCUT_EDITING = 'shell-keyboard-shortcut-editing',
  SHELL_PAGES_OPTIMIZATION = 'shell-pages-optimization',
  SHELL_GLOBAL_SEARCH_MOCK_API_RESPONSE = 'shell-global-search-mock-api-response',
  CONTACTS_TEAM_API = 'contacts-team-api',
  SHELL_CALENDAR_GETEVENTS_USE_ID = 'shell-calendar-getevents-use-id',
  SHELL_CHATBOT = 'shell-chatbot',
  SHELL_BLOCKED_EXTENSION_IDS = 'shell-blocked-extension-ids',
  SHELL_CAN_SEE_TRIAL_BANNER = 'shell-can-see-trial-banner',
  SHELL_REMOVE_FRAMEWORK_CSS = 'shell-remove-framework-css',
  SHELL_LOAD_SINGLE_EXTENSION = 'goto-shell-load-single-extension',
  SHELL_LOAD_VIEWS_ON_APP_START = 'goto-shell-load-views-on-app-start',
}

export enum BrandVariationKeys {
  FAVICON_ID = 'faviconId',
  HOME_PAGE_LOGO_ID = 'homePageLogoId',
  JIVE_BRAND_NAME = 'jiveBrandName',
  LOADING_LOGO_ID = 'loadingLogoId',
  ONBOARDING_COMPUTER_CALENDAR_ID = 'onboardingComputerCalendarId',
  ONBOARDING_COMPUTER_ID = 'onboardingComputerId',
  SHELL_BRAND_NAME = 'shellBrandName',
  TOPBAR_LOGO_ID = 'topBarLogoId',
  WELCOME_LOGO_ID = 'welcomeLogoId',
  CHAMELEON_THEME_PROVIDER_BRAND = 'chameleonThemeProvider',
}

export type BrandingMandatoryMembers = {
  readonly [key in BrandVariationKeys]: string
}

export type BrandingLaunchDarklyPayload = BrandingMandatoryMembers

export interface LaunchDarklyPayload {
  readonly [LaunchDarklyVariations.PKCE_REFRESH_TOKENS]?: {
    readonly enabled: boolean
    readonly maxRefreshTokens?: number
  }
  readonly [LaunchDarklyVariations.SHELL_ENABLE_DARKMODE]?: boolean
  readonly [LaunchDarklyVariations.BRANDING]?: BrandingLaunchDarklyPayload
  readonly [LaunchDarklyVariations.SHELL_APPLICATION_SWITCHER]?: boolean
  readonly [LaunchDarklyVariations.SHELL_NOTIFICATION_SETTINGS]?: boolean

  readonly [LaunchDarklyVariations.SHELL_AVATAR_POPOVER_HELP_MENU_ITEM]?: boolean
  readonly [LaunchDarklyVariations.SHELL_HOTKEY_SETTINGS]?: boolean
  readonly [LaunchDarklyVariations.SHELL_LANGUAGE_SETTINGS]?: boolean
  readonly [LaunchDarklyVariations.SHELL_ADVANCED_PRESENCE_BUSY]?: boolean
  readonly [LaunchDarklyVariations.SHELL_TOPBAR_ACCOUNT_SWITCHER]?: boolean
  readonly [LaunchDarklyVariations.SHELL_RESOLVE_USER_ACCESS_ATTACHMENT]?: boolean
  readonly [LaunchDarklyVariations.SHELL_NOTIFICATION_CHANNEL_V2]?: boolean
  readonly [LaunchDarklyVariations.SHELL_TRACK_NC_DISCONNECT_BANNER]?: boolean
  readonly [LaunchDarklyVariations.SHELL_DOCK_BADGE]?: boolean
  readonly [LaunchDarklyVariations.SHELL_APP_UPDATE_INTERVAL_OVERRIDE]?: string
  readonly [LaunchDarklyVariations.SHELL_KEYBOARD_SHORTCUT_EDITING]?: boolean
  readonly [LaunchDarklyVariations.SHELL_PAGES_OPTIMIZATION]?: boolean
  readonly [LaunchDarklyVariations.SHELL_GLOBAL_SEARCH_MOCK_API_RESPONSE]?: boolean
  readonly [LaunchDarklyVariations.CONTACTS_TEAM_FILTER_SHARED]?: boolean
  readonly [LaunchDarklyVariations.CONTACTS_TEAM_USE_TMP_BFF]?: boolean
  readonly [LaunchDarklyVariations.CONTACTS_TEAM_API]?: boolean
  readonly [LaunchDarklyVariations.VOICE_MULTILINE_REGISTRATION]?: boolean
  readonly [LaunchDarklyVariations.VOICE_REST_PHONE]?: boolean
}
