import type { ShellAction } from '../../common/global-props'
import { getShellLogger } from '../../common/logger'
import type { ShellExtension, ShellExtensionQuickActions } from '../../extensions'
import { getExtensionsManager } from '../../extensions/extensions-manager'
import { isMainWindow } from '../../services/container'
import { getPluginFunction, isContainer } from '../../services/container/helpers'

import type { ShellHook } from './models'
import { supportsQuickActions } from '../../extensions/utils'
import type { MenuItemConstructorOptions } from '@getgo/container-client'
import { onWindowUnload } from '../../common/helpers/window'

export const MESSAGE_CANNOT_ADD_QUICK_ACTION_UNSUPPORTED_METHOD =
  'Shell cannot add action items to the Desktop App. Add method is not supported on dock menu'

// eslint-disable-next-line functional/prefer-readonly-type
let currentActions: MenuItemConstructorOptions[] = []

const clearActions = () => {
  const removeDockTrayMenu = getPluginFunction('DockTrayMenu', 'remove')
  if (removeDockTrayMenu) {
    removeDockTrayMenu(currentActions.map(action => action.id ?? ''))
    currentActions = []
  }
}

export const initializeContainerQuickAction: ShellHook = async () => {
  if (!isContainer() || !isMainWindow()) {
    return
  }
  const addDockTrayMenu = getPluginFunction('DockTrayMenu', 'add')
  if (!addDockTrayMenu) {
    getShellLogger().debug(MESSAGE_CANNOT_ADD_QUICK_ACTION_UNSUPPORTED_METHOD)
    return
  }
  clearActions()
  currentActions = getExtensionsQuickActionMenuItems()
  if (currentActions.length) {
    try {
      currentActions = addDockTrayMenu(currentActions)
      onWindowUnload(() => {
        clearActions()
      })
    } catch (e) {
      getShellLogger().error(`Shell cannot add action items to container`, e)
    }
  }
}

const getExtensionsQuickActionMenuItems = () => {
  const result: MenuItemConstructorOptions[] = []
  const extensions = getExtensionsManager().getExtensions()
  return extensions.reduce((actions, extension) => actions.concat(getExtensionQuickActionMenuItems(extension)), result)
}

const getExtensionQuickActionMenuItems = (extension: ShellExtension): readonly MenuItemConstructorOptions[] => {
  if (supportsQuickActions(extension)) {
    const quickActions = (extension as ShellExtensionQuickActions).getQuickActions()
    return quickActions.map(action => actionToMenuItem(action, extension.getString))
  }
  return []
}

const actionToMenuItem = (
  action: ShellAction,
  getString: (key: string, ...params: readonly unknown[]) => string,
): MenuItemConstructorOptions => {
  const result: MenuItemConstructorOptions = { label: getString(action.labelKey ?? '') }
  if (action.children?.length) {
    result.submenu = action.children.map(subAction => actionToMenuItem(subAction, getString))
  }
  if (action.executeFunction) {
    const executeFunction = action.executeFunction
    result.click = () => {
      executeFunction()
    }
  }
  return result
}
