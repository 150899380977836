import type { Application, RegisterApplicationConfig } from 'single-spa'
import type { CustomProps, LifeCyclePromise } from '../../common'
import { createLifeCycleResolver } from '../../common'
import { GOTO_CONTENT_ID, GOTO_SETTINGS } from '../../common/container'
import { getDocument } from '../../common/dom-helpers'
import { getExtensionsManager } from '../extensions-manager'
import type { ShellExtension, ShellExtensionRegisterApplicationConfig } from '../shell-extension'
import type { ShellModule } from '../shell-module'
import { createAppForExtensionModule } from './application'
import { transformSettingsToShellModule, updateSettingsRoutes } from './settings'

type RegisterModuleToSpaOptions = {
  readonly domElementGetter?: () => HTMLElement | null
  readonly appWrapper?: (app: Application) => LifeCyclePromise
}

type RegisterModuleToSpaFn = (extension: ShellExtension, options?: RegisterModuleToSpaOptions) => void

/**
 * Register a list of ShellModule to singleSpa
 * @param extension the extension providing the ShellModule
 * @param modules the list of ShellModule
 * @param options options for the singeSpa registration
 */
const registerExtensionModuleToSingleSpa = (
  extension: ShellExtension,
  modules: readonly ShellModule[],
  options?: RegisterModuleToSpaOptions,
): void => {
  const { domElementGetter, appWrapper } = options ?? {}
  modules.forEach(module => {
    const component = createAppForExtensionModule(extension, module)
    const singleSpaApp: RegisterApplicationConfig = {
      activeWhen: component.activeWhen,
      app: appWrapper ? appWrapper(component.app) : component.app,
      name: component.name,
      customProps: {
        ...component.customProps,
        domElementGetter,
      },
    }
    getExtensionsManager().registerApplication(singleSpaApp.name, singleSpaApp)
  }, new Map<string, ShellExtensionRegisterApplicationConfig<CustomProps>>())
}

/**
 * Register all the settings modules
 * @param extension
 */
export const registerExtensionSettingsToSingleSpa: RegisterModuleToSpaFn = (extension, options): void => {
  // Transform SettingsDefinition to ShellModule
  const modules: readonly ShellModule[] = updateSettingsRoutes(extension.getSettings()).map(
    transformSettingsToShellModule,
  )
  registerExtensionModuleToSingleSpa(extension, modules, options)
}

/**
 * Register all the application modules
 * @param extension
 */
export const registerExtensionApplicationToSingleSpa: RegisterModuleToSpaFn = (extension, options): void => {
  const modules = extension.getModules()
  registerExtensionModuleToSingleSpa(extension, modules, options)
}

/**
 * Register extension's modules and settings as single-spa applications
 * @param extension Shell extension to register
 */
export const registerExtensionToSingleSpa = (extension: ShellExtension): void => {
  registerExtensionApplicationToSingleSpa(extension, {
    domElementGetter: () => getDocument().getElementById(GOTO_CONTENT_ID),
  })

  registerExtensionSettingsToSingleSpa(extension, {
    domElementGetter: () => getDocument().getElementById(GOTO_SETTINGS),
    appWrapper: createLifeCycleResolver,
  })
}
