import { asyncTryAndReturn } from '../../common/catcher'
import { performanceAsyncWrapper } from '../../common/performance-decorator'
import { setContextLocalStoragePbxInfo, setContextLocalStorageAccountKey, getPbxData } from '../../services/context'
import type { ContextApi, PbxData } from '../../services/context/models'
import { AccountContext, PbxContext } from '../../services/context/models'

/**
 * Sets up the context API with the given email and external user key.
 * @param email The email to use for fetching context data.
 * @param externalUserKey The external user key to use for fetching context data.
 * @returns A Promise that resolves to the context API object.
 */
export const setupContextApi = async (
  email: string | undefined,
  externalUserKey: string | undefined,
): Promise<ContextApi> =>
  await performanceAsyncWrapper('shell_contextApiSetup', async () => {
    let context: ContextApi = {}
    if (externalUserKey) {
      const pbxData = await fetchContextData(email, externalUserKey)

      if (pbxData) {
        storePbxDataInLocalStorage(pbxData, externalUserKey)
      }

      context = {
        account: new AccountContext(pbxData?.accountKey),
        pbx: new PbxContext(pbxData?.pbxId, pbxData?.pbxUserId, pbxData?.pbxFlags),
      }
    }

    return context
  })()

const fetchContextData = async (email: string | undefined, externalUserKey: string): Promise<PbxData | undefined> => {
  let pbxData

  if (email) {
    pbxData = await asyncTryAndReturn({ func: () => getPbxData(email, externalUserKey), defaultReturn: undefined })
  }

  return pbxData
}

const storePbxDataInLocalStorage = (pbxData: PbxData, externalUserKey: string): void => {
  if (pbxData?.pbxId) {
    setContextLocalStoragePbxInfo({ externalUserKey, pbxId: pbxData?.pbxId, pbxUserId: pbxData?.pbxUserId })
  }
  if (pbxData?.accountKey) {
    setContextLocalStorageAccountKey(externalUserKey, pbxData.accountKey)
  }
}
