import { navigateToUrl } from '../common'
import { removeFromLocalStorage } from '../common/dom-helpers'
import { getShellLogger } from '../common/logger'
import { LANDING_ROUTE, UNAUTHENTICATED_ERROR } from '../common/routes'
import { type HttpResponseError } from '../http-utils'
import { LOCAL_STORAGE_RELOAD_ATTEMPTS_KEY } from '../packages/error-page/component'
import { auth, isAuthenticationRequired, loginOnce } from '../services/auth'
import { removeRefreshToken, removeToken } from '../services/auth/token'
import { getExternalInterface } from '../services/external-interface'
import { getUserInfo } from '../services/identity'
import { type UserInfo } from '../services/identity/models'
import stackLogger from '../services/stack-logger'

export const fetchUserInfoFromInternalAPIs = async (): Promise<UserInfo | undefined> => {
  const hasToken = await auth()

  if (!hasToken) {
    return
  }

  try {
    const userInfo = await getUserInfo()

    //local storage key used to log user out from error page
    removeFromLocalStorage(LOCAL_STORAGE_RELOAD_ATTEMPTS_KEY)
    return userInfo
  } catch (error) {
    const errorResponse = error as HttpResponseError<Response>

    getShellLogger().error('fetchUserInfoFromInternalAPIs fail when calling getUserInfo', error)

    const isAuthRequired = await isAuthenticationRequired()

    if (errorResponse.status === 401 && isAuthRequired) {
      removeToken()
      removeRefreshToken()
      stackLogger.addEvent('fetchUserInfoFromInternalAPIs: Error response 401') // NOSONAR
      await loginOnce()
    } else if (errorResponse.status === 403) {
      const navigationUrl = getExternalInterface().isCompanion ? LANDING_ROUTE : UNAUTHENTICATED_ERROR
      removeToken()
      removeRefreshToken()
      stackLogger.addEvent('fetchUserInfoFromInternalAPIs: Error response 403') // NOSONAR
      navigateToUrl(navigationUrl)
    } else if (isAuthRequired) {
      //Interrupt the Shell.start() process
      throw error
    }
  }
}
