import type { GlobalSearchActionUI, GlobalSearchResultItem } from './../global-search-models'
import {
  SVG_BADGE_OUTLINED,
  SVG_MESSAGE_OUTLINED,
  SVG_PHONE_UP_ACTIVE,
  SVG_WEBCAM_ON_OUTLINED,
} from '@getgo/chameleon-icons'

export const NUMBER_OF_PREVIEW_ITEMS = 5

export const mockSearchString = '456'

export const callAction = {
  id: 'action id',
  displayName: 'call',
  icon: SVG_PHONE_UP_ACTIVE,
  command: { namespace: 'call', commandName: 'callPhone' },
}

export const messageAction = {
  id: 'action id',
  displayName: 'message',
  icon: SVG_MESSAGE_OUTLINED,
  command: { namespace: 'message', commandName: 'sendMessage' },
}

export const videoAction = {
  id: 'action id',
  displayName: 'video',
  icon: SVG_WEBCAM_ON_OUTLINED,
  command: { namespace: 'video', commandName: 'startMeeting' },
}

export const mockSingleSearchResult = [
  {
    userKey: 'result1',
    title: 'Kermit The Frog',
    preview: '123-456-7890',
    actions: [callAction],
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  },
]

export const mockSearchResults = [
  {
    userKey: 'result1',
    title: 'Kermit The 456 Frog',
    preview: '123-456-7890',
    actions: [callAction],
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  },
  {
    userKey: 'result2',
    title: '456Miss Piggy',
    preview: '123-456-7890',
    actions: [messageAction],
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  },
  {
    userKey: 'result3',
    title: 'Fozzie Bear456',
    preview: 'really456incrediblyextremelylongemailaddress@superlongemailaddressdomainnamewebsite.com',
    actions: [videoAction, messageAction, callAction],
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  },
  {
    title: 'Number One Winner',
    preview: '123-456-7890',
    actions: [callAction],
    icon: SVG_BADGE_OUTLINED,
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  },
  {
    externalUserKey: 'result2',
    title: 'This contact has a very long name this time and this is it wow it just keeps going I cannot believe it',
    preview: '123-456-7890',
    actions: [messageAction],
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  },
  {
    userKey: 'result3',
    title: 'Fozzie Bear',
    preview: 'reallylongemailaddress@superlongemailaddress.com',
    actions: [videoAction, messageAction],
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  },
  {
    userKey: 'result1',
    title: 'Kermit The Frog',
    preview: '123-456-7890',
    actions: [callAction],
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  },
  {
    userKey: 'result2',
    title: 'Miss Piggy',
    preview: '123-456-7890',
    actions: [messageAction],
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  },
  {
    userKey: 'result3',
    title: 'Fozzie Bear',
    preview: '123-456-7890',
    actions: [videoAction],
    originalResult: {} as GlobalSearchResultItem,
    defaultAction: {} as GlobalSearchActionUI,
  },
]

export const mockSearchResultPreviewItemExternalUserKey = {
  userKey: '123456789',
  title: 'Carly Rae Jepsen',
  preview: 'Call me maybe',
  actions: [callAction],
  originalResult: {} as GlobalSearchResultItem,
  defaultAction: callAction,
  givenName: 'Carly Rae',
  familyName: 'Jepsen',
}

export const mockSearchResultPreviewItemIcon = {
  icon: SVG_BADGE_OUTLINED,
  title: 'Carly Rae Jepsen',
  preview: 'Call me maybe',
  actions: [callAction, messageAction],
  originalResult: {} as GlobalSearchResultItem,
  defaultAction: {} as GlobalSearchActionUI,
}

export const mockSearchResultPreviewNoActions = {
  icon: SVG_BADGE_OUTLINED,
  title: 'Carly Rae Jepsen',
  preview: 'Call me maybe',
  actions: [],
  originalResult: {} as GlobalSearchResultItem,
  defaultAction: {} as GlobalSearchActionUI,
}

export const searchRecords = [
  {
    name: 'Marie',
    phone: '555-1231',
    email: 'marie@goto.com',
  },
  {
    name: 'Jose',
    phone: '555-1232',
    email: 'jose@goto.com',
  },
  {
    name: 'Brian',
    phone: '555-1233',
    email: 'brian@goto.com',
  },
  {
    name: 'Vincent',
    phone: '555-1234',
    email: 'vincent@goto.com',
  },
  {
    name: 'Daniel',
    phone: '555-1235',
    email: 'daniel@goto.com',
  },
]

export const convertedSearchRecords = [
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[0] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: '',
    originalResult: { data: searchRecords[1] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '123456789',
    familyName: 'Snow',
    givenName: 'Jon',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[2] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[3] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[4] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[0] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: '',
    originalResult: { data: searchRecords[1] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '123456789',
    familyName: 'Snow',
    givenName: 'Jon',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[2] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[3] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[4] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[0] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: '',
    originalResult: { data: searchRecords[1] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '123456789',
    familyName: 'Snow',
    givenName: 'Jon',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[2] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[3] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[4] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[0] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: '',
    originalResult: { data: searchRecords[1] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '123456789',
    familyName: 'Snow',
    givenName: 'Jon',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[2] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[3] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[4] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[0] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: '',
    originalResult: { data: searchRecords[1] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '123456789',
    familyName: 'Snow',
    givenName: 'Jon',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[2] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[3] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[4] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[0] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: '',
    originalResult: { data: searchRecords[1] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '123456789',
    familyName: 'Snow',
    givenName: 'Jon',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[2] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[3] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
  {
    actions: [],
    defaultAction: messageAction,
    icon: 'SVG_EMOJI_HAPPY_OUTLINED',
    originalResult: { data: searchRecords[4] },
    preview: 'mockPreview1',
    title: 'mockTitle1',
    userKey: '',
    familyName: '',
    givenName: '',
  },
]
