import { getShellLogger } from '../../common/logger'
import type { EOMInterface, EOMInterfaceIdentifier } from '../../eom'
import type { ShellExtension } from '../../extensions'
import { getExtensionsManager } from '../../extensions/extensions-manager'
import { assertSchema, supportsEOM } from '../../extensions/utils'
import type { ExtensionInterface, ExtensionsAPI } from './models'

let _extensionsAPI: ExtensionsAPI | undefined

class EOMImplementationError extends Error {
  constructor(
    public key: string,
    public actualType: string,
    public expectedType: string,
    public message: string = '',
  ) {
    super()
  }
}

const toId = (intf: string | EOMInterfaceIdentifier) => (typeof intf === 'string' ? intf : intf.uid)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toSchema = (intf: string | EOMInterfaceIdentifier): { readonly [key: string]: any } => {
  if (typeof intf === 'string') {
    return {}
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const schema: { [key: string]: any } = { ...intf }
    delete schema['uid']
    return schema
  }
}

const validateSchema = <I extends EOMInterface, T extends object>(
  object: T,
  intf: string | (I & EOMInterfaceIdentifier),
  extension: ShellExtension,
): object is T => {
  try {
    const schema = toSchema(intf)
    assertSchema(object, schema)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = `The extension (${extension.id}) is not compliant with the ShellExtension interface (uid: '${toId(
      intf,
    )}'). The implementation ${e.key} type should be ${e.expectedType} instead of ${e.actualType}.`
    getShellLogger().error(new EOMImplementationError(e.key, e.actualType, e.expectedType, message))
    return false
  }
  return true
}

export const getExtensionsAPI = (): ExtensionsAPI => {
  if (!_extensionsAPI) {
    _extensionsAPI = {
      queryInterface: <I extends EOMInterface>(intf: string | (I & EOMInterfaceIdentifier)) => {
        const manager = getExtensionsManager()
        // TODO: SCORE-2536 - load extensions not loaded yet that contains supported interface if they meet the prerequisites
        const implementations = manager.getExtensions().reduce((acc: ExtensionInterface<I>[], extension) => {
          if (supportsEOM(extension) && extension.supportsInterface(intf)) {
            const impl = extension.queryInterface(intf)
            if (typeof intf === 'string' || validateSchema(impl, intf, extension)) {
              acc.push({ impl, extension })
            }
          }
          return acc
        }, [])

        return Promise.resolve(implementations)
      },
      isStandaloneRoute: (route: string) => getExtensionsManager().isStandaloneRoute(route),
      isExtensionReady: (extensionId: string) => getExtensionsManager().isExtensionReady(extensionId),
      onExtensionReady: (extensionId: string) => getExtensionsManager().onExtensionReady(extensionId),
    }
  }
  return _extensionsAPI
}
