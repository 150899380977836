import type { ExtensionConfig } from '@goto/shell'

export const extensionConfig: ExtensionConfig = {
  id: '@ucc/gtc-cc-qc-scorecard.evaluation',
  url: new URL('/experiences/cc-qm-scorecard-eval/goto-app-extension.js', location.origin),
  prerequisites: {
     ccFlags: 'cc-supervisor.quality-management-dashboard',
  },
  integrationConfig: {
    shouldLoadInCompanion: true,
    shouldLoadInIntegration: true,
  }
}

export default extensionConfig

