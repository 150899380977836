import type { LaunchRequest } from '@getgo/container-client'
import { getShellLogger } from '../../common/logger'
import { getNamespaces } from '../namespaces/namespace-manager'

export interface ShellNamespace {
  readonly commands: LauncherCommands
}

export type LaunchHandler = (launchRequest: LaunchRequest) => boolean

export interface LauncherCommands {
  // A simple command that accepts launch handlers from Experiences
  registerLauncherHandler(launchHandler: LaunchHandler): void
}

export const SHELL_NAMESPACE = 'shell'

/**
 * A simple class to allow bridging the Container Lifecycle Plugin
 * with Background services provided by an Experience Team.
 *
 * This lets the Container pass the details of launch triggers (such
 * as tel:, goto:, etc.) directly to an Experience's background service
 * and allow the Experience to react accordingly
 */
export class Launcher implements LauncherCommands {
  private constructor() { }
  private static instance: Launcher | undefined
  private readonly launchHandlers: LaunchHandler[] = []
  private currentLaunchRequest: LaunchRequest | null = null

  static getInstance() {
    if (!Launcher.instance) {
      Launcher.instance = new Launcher()
    }
    return Launcher.instance
  }

  registerLauncherHandler(launchHandler: LaunchHandler) {
    const launcher = Launcher.getInstance()
    launcher.launchHandlers.push(launchHandler)
    if (launcher.currentLaunchRequest && launchHandler(launcher.currentLaunchRequest)) {
      launcher.currentLaunchRequest = null
    }
  }

  appLauncher(launchRequest: LaunchRequest) {
    const launcher = Launcher.getInstance()
    // The following log is for debugging purposes only
    // It is added in the context of the investigation of https://jira.ops.expertcity.com/browse/SCORE-2804
    // this is an ongoing investigation and this log will be removed once the investigation is complete
    getShellLogger().log(
      'appLauncher',
      `LaunchParams URL (${launchRequest.url})`,
      `LaunchParams config (${JSON.stringify(launchRequest.config)})`,
      `LaunchParams Params (${JSON.stringify(launchRequest.params)})`,
    )
    const requestSentSuccessfully = launcher.launchHandlers.some(launchHandler => {
      try {
        return launchHandler(launchRequest)
      } catch (e) {
        getShellLogger().warn(
          'appLauncher',
          `LaunchParams (${launchRequest.url})`,
          'Launcher from an Extension threw an error',
          e,
        )
        return false
      }
    })
    if (!requestSentSuccessfully) {
      launcher.currentLaunchRequest = launchRequest
    }
  }

  setupLauncherCommands() {
    const launcher = Launcher.getInstance()
    const shellNamespace = getNamespaces().retrieve<ShellNamespace>(SHELL_NAMESPACE)
    shellNamespace.commands.registerLauncherHandler.addHandler(launcher.registerLauncherHandler)
  }
}
