import type { NavigationLink } from '../../common'
import { getNavigationItemsService } from './navigation-item/navigation-items-service'
import { getLocationPathname } from '../../common/dom-helpers'
import { sanitizeExternalIntegrationRoute } from '../../core/integrations-helpers'
import { navigateToStoredRoute } from '../../common/store-current-route'
import { ROOT_ROUTE } from '../../common/routes'

export const sortNavigationLinks = (items: NavigationLink[]): NavigationLink[] => {
  const navigationOrder = getNavigationItemsService().getNavigationOrder()
  if (!navigationOrder) {
    return items
  }
  const unknownItemIndex = navigationOrder.length
  const compareItems = (item1: NavigationLink, item2: NavigationLink): number => {
    let index1 = navigationOrder.indexOf(item1.id)
    index1 = index1 === -1 ? unknownItemIndex : index1
    let index2 = navigationOrder.indexOf(item2.id)
    index2 = index2 === -1 ? unknownItemIndex : index2
    return index1 - index2
  }
  return items.sort(compareItems)
}

/**
 * Sanitize the location path name to remove EXTERNAL_INTEGRATION_ROUTES like `/ms-teams`
 * and navigate to the stored route if the url is on the home route "/"
 */
export const redirectToStoredRouteOnRootRoute = () => {
  if (sanitizeExternalIntegrationRoute(getLocationPathname()) === ROOT_ROUTE) {
    navigateToStoredRoute()
  }
}
