import type { EOMInterface } from '../eom'
import type { GlobalSearchResultItem } from '../packages/global-search'
import type { CommandDefinition } from '../services/namespaces'

/**
 * Interface defining a Global search action
 */
export interface GlobalSearchAction {
  readonly id: string
  readonly displayNameKey: string
  readonly icon: string
  readonly command: CommandDefinition
}

/**
 * Global search contexts
 * preview - Preview context is when results are shown in the preview popover of the search field
 * list - List context is when results are shown in a result list (usually the search result page)
 */
export type GlobalSearchContext = 'preview' | 'list'

/**
 * Type for functions that are used to get display value for an item
 */
export type GlobalSearchValueFunction = (item: GlobalSearchResultItem) => string

/**
 * Type for definition of a result field.  It can be a property name, or a function
 */
export type GlobalSearchValueField = string | GlobalSearchValueFunction

/**
 * Definition of a category column
 */
export interface GlobalSearchCategoryColumn {
  /**
   * Column Identifier
   */
  readonly id: string
  /**
   * Key to retrieve display name of column using getString() on extension
   */
  readonly displayNameKey: string
  /**
   * Property name or callback to retrieve the value to display for a result for the column
   */
  readonly valueField: GlobalSearchValueField
}

/**
 * Definition of the empty state when no results are found
 */
export interface GlobalSearchEmptyState {
  /**
   * The translatable message key of the message displayed to the user when no results were found for their search query
   */
  readonly messageKey: string
  /**
   * The possible actions that can be performed by the user for their search query
   */
  readonly actions: readonly GlobalSearchAction[]
}

/**
 * Definition of the avatar fields
 */
export interface GlobalSearchAvatar {
  /**
   * Property name or callback to retrieve the userKey for the avatar image to display with the search results
   */
  readonly userKey: GlobalSearchValueField
  /**
   * Property name or callback to retrieve the given name for the avatar initials to display with the search results
   * givenName => firsttName in North America
   */
  readonly givenName: GlobalSearchValueField
  /**
   * Property name or callback to retrieve the family name for the avatar initals to display with the search results
   * familyName => lastName in North America
   */
  readonly familyName: GlobalSearchValueField
}

/**
 * Interface describing the query object for the Global Search API
 */
export interface GlobalSearchQuery<Permissions = { readonly [key: string]: unknown }> {
  /**
   * The application (extension) that this query is for
   */
  readonly application: string
  /**
   * The query string itself
   */
  readonly query: string
  /**
   * Any data that the Global Search API needs to ensure the right data is returned for the right user
   * These will be determined by the cbox team
   */
  readonly permissionParameters: Permissions
}

/**
 * Definition of a global search category
 */
export interface GlobalSearchCategory {
  /**
   * Category identifier
   */
  readonly id: string
  /**
   * Category application name that matches the `application` value returned from API
   */
  readonly application: string
  /**
   * List of columns used for result page
   */
  readonly columns: readonly GlobalSearchCategoryColumn[]
  /**
   * Property name or callback to retrieve the Title to display for results of that category
   */
  readonly titleField: GlobalSearchValueField
  /**
   * Property name or callback to retrieve the Preview to display for results of that category
   */
  readonly previewField: GlobalSearchValueField
  /**
   * The data to be presented to the user when no search results are found
   */
  readonly emptyState: GlobalSearchEmptyState
  /**
   * Data needed to display the avatar
   */
  readonly avatarFields?: GlobalSearchAvatar
  /**
   * Property name or callback to retrieve the Icon to display for results of that category if no avatarFields defined
   */
  readonly iconField?: GlobalSearchValueField
  /**
   * Retrieves the query for the search engine for a given search string
   * @param searchString string used to search
   */
  getQueryForSearchString(searchString: string): GlobalSearchQuery
  /**
   * Retrieves the default action that can be executed on a given search result by default
   * @param searchResult Search result record
   * @param context Search result context.  Can be 'preview' or 'list'
   */
  getResultDefaultAction(
    searchResult: GlobalSearchResultItem,
    context: GlobalSearchContext,
  ): GlobalSearchAction | undefined
}

export const GlobalSearchShellIntegrationIdentifier = '70b6ef46-02f1-4303-a325-e1eb45afee99'

/**
 * Interface that needs to be implemented by an extension that want to interact with global search features
 */
export interface GlobalSearchShellIntegration extends EOMInterface {
  readonly uid: '70b6ef46-02f1-4303-a325-e1eb45afee99'

  /**
   * Retrieves the categories for global search defined by extension
   */
  getGlobalSearchCategories(): readonly GlobalSearchCategory[]

  /**
   * Retrieves the actions that can be executed on a given search result
   * @param searchResult Search result record
   * @param context Search result context.  Can be 'preview' or 'list'
   */
  getGlobalSearchResultActions(
    searchResult: GlobalSearchResultItem,
    context: GlobalSearchContext,
  ): readonly GlobalSearchAction[] | undefined

  /**
   * Retrieves the actions that can be executed on a given search string
   * @param searchString string used to search
   * @param context Search context.  Can be 'preview' or 'list'
   */
  getActionsForSearchString(
    searchString: string,
    context: GlobalSearchContext,
  ): readonly GlobalSearchAction[] | undefined
}

/* istanbul ignore next -> this is only a schema object so the functions are never called */
export const GlobalSearchShellIntegrationSchema: GlobalSearchShellIntegration = {
  uid: GlobalSearchShellIntegrationIdentifier,
  getGlobalSearchCategories: () => [],
  getGlobalSearchResultActions: () => [],
  getActionsForSearchString: () => [],
}
