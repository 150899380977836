import { html, property } from 'lit-element'
import { getTranslation } from '../../../services/i18n/i18nUtils'
import { navigateToUrl, type NavigationLink } from '../../../common'
import { repeat } from 'lit-html/directives/repeat'
import { linkIsActive } from '../navigation-item/helpers'
import { setStoreCurrentRoute } from '../../../common/store-current-route'
import { ifDefined } from 'lit-html/directives/if-defined'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { getResolvedIcon } from '../../../common/icon-helpers'
import { nothing } from 'lit-html'
import { t } from '../../../directives/translate'
import type { InternalNavigationLink } from '../types'
import navBarStyles from './navigation-bar.styles.scss'
import { GoToBaseNavigation } from '../base-navigation/base-navigation'
import { type NavBarItemComponent } from '@getgo/chameleon-web'

export class GoToNavBar extends GoToBaseNavigation {
  static readonly tagName = 'goto-nav-bar'
  @property({ type: Array, attribute: 'links', reflect: true }) links: InternalNavigationLink[] = []

  static get styles() {
    return navBarStyles
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
    super.attributeChangedCallback(name, oldValue, newValue)
    if (name === 'links' && newValue) {
      this.setNavigationItems()
    }
  }

  private readonly setNavigationItems = () => {
    this.navigationItems = this.links

    this.subscribeToBadgeEvent()
  }

  render() {
    return html`
      <chameleon-nav-bar
        class="fade-in"
        label=${t('GoTo navigation menu')}
        @ch-nav-bar-change=${(e: CustomEvent<{ target: NavBarItemComponent }>) => {
          if (!e.detail.target.external) {
            setStoreCurrentRoute(e.detail.target.href)
            navigateToUrl(e.detail.target.href)
          }
        }}
      >
        ${repeat(this.navigationItems, this.renderLink)}
      </chameleon-nav-bar>
    `
  }

  private readonly renderLink = (navItem: NavigationLink) => {
    const active = linkIsActive(navItem.href)

    return html`
      <chameleon-nav-bar-item
        .href=${navItem.href}
        data-test="${ifDefined(navItem.dataTest)}"
        ?active=${active}
        ?external=${navItem.externalLink}
      >
        <chameleon-svg slot="icon"
          >${navItem.icon instanceof Function
            ? unsafeSVG(navItem.icon(active))
            : unsafeSVG(getResolvedIcon(navItem.icon, { active }))}
        </chameleon-svg>
        ${this.renderBadge(navItem)} ${getTranslation(navItem.name)}
      </chameleon-nav-bar-item>
    `
  }

  private renderBadge(navItem: NavigationLink) {
    const badgeCount = this.getBadgeCount(navItem)
    return badgeCount && badgeCount > 0
      ? html`
          <chameleon-badge-counter
            size="small"
            slot="badge"
            data-test="badge-${ifDefined(navItem.dataTest)}"
            label=${t('New notifications')}
          >
            ${badgeCount}</chameleon-badge-counter
          >
        `
      : nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToNavBar.tagName]: GoToNavBar
  }
}
