import type { UpdateInfo } from '@getgo/container-client/lib/cjs'
import { getEventBus, eventEmitter } from '../namespaces/event-bus'

export const UpdateNamespace = 'UpdateNotifications'

export const UpdateEvents = {
  updateAvailable: eventEmitter<UpdateInfo>(),
  checkForUpdatesStartedOrStopped: eventEmitter<boolean>(),
}

const { emit } = getEventBus().register(UpdateNamespace, UpdateEvents)

export const updateAvailable = (update: UpdateInfo) => {
  emit.updateAvailable(update)
}

export const checkForUpdateStartedOrStopped = (inProgress: boolean) => {
  emit.checkForUpdatesStartedOrStopped(inProgress)
}
