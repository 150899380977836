import { getShellLogger } from '../../../common/logger'
import {
  getDocument,
  getLocationHash,
  getLocationHREF,
  getLocationOrigin,
  getLocationPathname,
  getLocationSearch,
  getWindow,
  isInIframe,
  postMessageOnMainWindow,
} from '../../../common/dom-helpers'
import { environment } from '../../../environments'
import {
  API_LOGGED_PARAMETER,
  API_NOT_LOGGED_PARAMETER,
  API_REDIRECT_URL_PATHNAME,
  DEFAULT_ISLOGGEDIN_STATE,
  IFRAME_ID,
  ISLOGGEDIN_IFRAME_MESSAGE_TYPE,
} from './constant'
import { IsLoggedInState } from './isLoggedIn-state'

const MESSAGE_IDENTIFICATION = 'IsLoggedIn Iframe'
export const ABOUT_BLANK = 'about:blank'

/**
 * Returns true if the iframe's src is 'about:blank'.
 *
 * @param iframe
 * @returns boolean
 */
export const isIframeInitializing = (iframe: HTMLIFrameElement) => iframe.src === ABOUT_BLANK

export const createSecureIframe = () => {
  const iframe = getDocument().createElement('iframe')

  iframe.id = IFRAME_ID
  iframe.style.display = 'none'
  iframe.src = ABOUT_BLANK
  // We only create the iframe, the url is only set on demand
  // when we need the information about the user
  // iframe.src = ''

  /**
   * The iframe is given a real src attribute only after the global environment variable
   * (globalThis.shellEnvironmentConfig.environment) is set. The logger expects the
   * global environment variable to be set. So if the iframe.src is set to anything other
   * that the default we know it's safe for the event handlers to call the logger.
   *
   * This is required to avoid a race condition where the shell logger is called before
   * the shell is fully started since an iframe fires a 'load' event when it's appended to
   * the DOM even if a src attribute was not given.
   */
  iframe.onload = () => {
    if (!isIframeInitializing(iframe)) {
      getShellLogger().info(`${MESSAGE_IDENTIFICATION}: Loaded ${getLocationHREF()}`)
    }
  }
  iframe.onerror = (event: Event | string) => {
    if (!isIframeInitializing(iframe)) {
      getShellLogger().error(event)
      IsLoggedInState.getInstance().update(DEFAULT_ISLOGGEDIN_STATE)
    }
  }

  const isSameDomain = ({ origin }: MessageEvent) => origin === getLocationOrigin()
  const isIsLoggedInMessage = ({ data }: MessageEvent) => data.type === ISLOGGEDIN_IFRAME_MESSAGE_TYPE

  getWindow().addEventListener('message', (event: MessageEvent) => {
    if (isSameDomain(event) && isIsLoggedInMessage(event)) {
      IsLoggedInState.getInstance().update(event.data.isLoggedIn)
    }
  })

  return iframe
}

const newSecureIframeURL = () => {
  const { authUrl, authClientId } = environment()

  const params = new URLSearchParams({
    response_type: 'token',
    client_id: authClientId,
    redirect_uri: `${getLocationOrigin()}${API_REDIRECT_URL_PATHNAME}`,
    gateway: 'true',
  })

  return `${authUrl}/oauth/authorize?${params}`
}

export const reloadIsLoggedInIframe = () => {
  const iframe = getDocument().getElementById(IFRAME_ID)

  if (isIframeElement(iframe)) {
    iframe.src = newSecureIframeURL()
  } else {
    getShellLogger().debug(
      `${MESSAGE_IDENTIFICATION}: Iframe not found, iframe should be added to the dom before reloading`,
    )
  }
}

export const isInAuthFlowIframe = () => isInIframe() && getLocationPathname() === API_REDIRECT_URL_PATHNAME

const isIframeElement = (element: unknown): element is HTMLIFrameElement =>
  !!element && element instanceof HTMLIFrameElement

const getParametersFromURL = () => {
  if (!getLocationSearch()) {
    return new URLSearchParams(getLocationHash().replace(/#/, '?'))
  }

  return new URLSearchParams(getLocationSearch())
}

export const postMessageFromInsideIframe = () => {
  if (getLocationPathname() === API_REDIRECT_URL_PATHNAME) {
    const params = getParametersFromURL()

    if (params.has(API_NOT_LOGGED_PARAMETER)) {
      postMessage(false)
    } else if (params.has(API_LOGGED_PARAMETER)) {
      postMessage(true)
    } else {
      getShellLogger().error(
        `${MESSAGE_IDENTIFICATION}: Missing parameter ('${API_LOGGED_PARAMETER}' or '${API_NOT_LOGGED_PARAMETER}') from the URL `,
      )
    }
  }
}

const postMessage = (isLoggedIn: boolean) =>
  postMessageOnMainWindow({ type: ISLOGGEDIN_IFRAME_MESSAGE_TYPE, isLoggedIn }, '*')
