import type { CustomProps } from '../../common'
import { getShellLogger } from '../../common/logger'
import type { ComponentDefinition } from '../component-definition'
import type { ShellExtension, ShellExtensionRegisterApplicationConfig } from '../shell-extension'
import type { RouteMatcher, ShellModule } from '../shell-module'

export function isPathMatchingRoute(path: string, route: RouteMatcher) {
  return route instanceof RegExp ? path.match(route) : path === route
}

export function assertModuleRoutes(name: string, routes: RouteMatcher[]) {
  // Assert the module routes do not specify the root route. No module should be mounted on the root route.
  const rootRoute = '/'
  if (routes.some(route => isPathMatchingRoute(rootRoute, route))) {
    // For now just log an error. We still have `@jive/web/Home/GTC` mounted on the root route when jiveweb is enabled.
    // We can monitor this error log in Splunk, and when jiveweb is disabled, we can consider converting to an exception.
    getShellLogger().error('Modules must not specify the root route', { name })
  }
}

export function isPathIncluded(pathname: string, url: string): boolean {
  const normalizedPathname = pathname.replace(/^\/|\/$/g, '')
  const path = isURL(url) ? new URL(url).pathname : url
  const regex = new RegExp(`^/${normalizedPathname}(/|$)`)
  return regex.test(path)
}

const isURL = (url: string) => url.startsWith('http://') || url.startsWith('https://')

/**
 * @private
 * Creates an application configuration object (single-spa) for a given module.
 * @param extension Shell extension instance that contains the module
 * @param module Module for which to create a single-spa application
 * @returns created single-spa configuration object
 */
export function createAppForExtensionModule(
  extension: ShellExtension,
  module: ShellModule,
): ShellExtensionRegisterApplicationConfig<CustomProps> {
  const name = `${extension.id}/${module.displayNameKey}/${module.componentId}`
  const routes: RouteMatcher[] = [...module.routes]

  assertModuleRoutes(name, routes)

  return {
    name,
    routes,
    activeWhen: location => routes.some(route => isPathMatchingRoute(location.pathname, route)),
    // TODO handle null component definition https://jira.ops.expertcity.com/browse/SCORE-1405
    app: () => extension.getComponentById(module.componentId) as Promise<ComponentDefinition>,
    customProps: {
      shellConfig: module.shellConfig ?? {},
    },
  }
}
