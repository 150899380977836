import { getShellLogger } from '../../common/logger'
import type { ExtensionConfig } from '../../extensions'

/**
 * Helper to get unguarded routes from extensions
 * @param configs
 * @returns
 */

const shellguardedRoutes = ['/']

const isGuardedRoute = (route: string) => shellguardedRoutes.some(guardedRoute => guardedRoute === route)

export const getUnguardedRoutes = (configs: readonly ExtensionConfig[]): readonly string[] => {
  const unguardedRoutes: Set<string> = new Set()
  configs.forEach(extensionConfig => {
    if (extensionConfig) {
      extensionConfig?.unguardedRoutes?.forEach(route => {
        if (isGuardedRoute(route)) {
          getShellLogger().error(`${route} is a protected route and cannot be added to unguarded routes`)
        } else {
          unguardedRoutes.add(route)
        }
      })
    }
  })
  return Array.from(unguardedRoutes)
}
