/*istanbul ignore file*/

// Temporary file for
interface Event {
  message: string
}

interface StackLogger {
  maxStackLength: number
  addEvent(message: string, ...args: unknown[]): void
  getEvents(): Event[]
  clearEvents(): void
}

class StackLoggerTemp implements StackLogger {
  private stack: Event[] = []

  private static instance: StackLoggerTemp

  private constructor() {}

  maxStackLength = 50

  static getInstance(): StackLoggerTemp {
    if (!StackLoggerTemp.instance) {
      StackLoggerTemp.instance = new StackLoggerTemp()
    }
    return StackLoggerTemp.instance
  }

  addEvent(message: string) {
    try {
      const eventToAdd = {
        message,
        timestamp: new Date().toISOString(),
      }
      if (this.stack.length >= this.maxStackLength) {
        this.stack.shift()
      }
      this.stack.push(eventToAdd)
    } catch {
      // do nothing
    }
  }

  getEvents() {
    return this.stack
  }

  clearEvents() {
    this.stack = []
  }
}

export default StackLoggerTemp.getInstance()
