/* istanbul ignore file */
import { singleSpaLitSettings } from '../../common'
import { html } from 'lit-element'
import { GOTO_SETTINGS_SELECTOR } from '../../common/container'

const lifecycles = singleSpaLitSettings({
  template: () => html`<goto-notifications-settings></goto-notifications-settings>`,
  domElement: GOTO_SETTINGS_SELECTOR,
})

export default lifecycles
