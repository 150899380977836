/*
  A helper to add LitHtml-based apps to single-spa
  Inspired from single-spa-html
  https://github.com/single-spa/single-spa-html
 */

import type { TemplateResult } from 'lit-html'
import { parts, removeNodes, render } from 'lit-html'
import type { AppProps } from 'single-spa'
import { waitForElement } from '../common/dom-helpers'
import { waitForNextEventCycle } from './helpers'

export type TemplateGenerator<Props extends SingleSpaLitProps | undefined> = (props?: Props) => TemplateResult

interface SingleSpaLitOptions<Props extends SingleSpaLitProps> {
  readonly template: TemplateResult | TemplateGenerator<Props>
  readonly domElement?: string | Element | (() => Element | null | undefined)
}

export interface SingleSpaLitProps extends AppProps {
  readonly appName?: string
}

export function singleSpaLit<Props extends SingleSpaLitProps>(opts: SingleSpaLitOptions<Props>) {
  return {
    bootstrap: () => bootstrap(),
    mount: (props?: Props) => mount(opts, props),
    unmount: (props?: Props) => unmount(opts, props),
  }
}

export function singleSpaLitSettings<Props extends SingleSpaLitProps>(opts: SingleSpaLitOptions<Props>) {
  return {
    ...singleSpaLit(opts),
    mount: async (props?: Props) => {
      await waitForElement('goto-settings', document.body)
      // The below function call is to wait for the next render cycle to ensure all of the settings dom is fully mounted
      await waitForNextEventCycle()
      mount(opts, props)
    },
  }
}

/* istanbul ignore next not useful for unit test */
async function bootstrap() {}

async function mount<Props extends SingleSpaLitProps>(opts: SingleSpaLitOptions<Props>, props?: Props) {
  const domEl = await domElementGetter(opts, props)

  render(typeof opts.template === 'function' ? opts.template(props) : opts.template, domEl)
}

async function unmount<Props extends SingleSpaLitProps>(opts: SingleSpaLitOptions<Props>, props?: Props) {
  const domEl = await domElementGetter(opts, props)

  parts.delete(domEl)

  removeNodes(domEl, domEl.firstChild)
}

function domElementGetter<Props extends SingleSpaLitProps>(opts: SingleSpaLitOptions<Props>, props?: Props): Element {
  const name = props?.appName ?? props?.name

  if (!name) {
    throw Error(
      `single-spa-lit was not given an application name as a prop, so it can't make a unique dom element container for the lit application`,
    )
  }
  const htmlId = `single-spa-application:${name}`

  let domElement: Element | null | undefined
  if (opts.domElement instanceof Element) {
    domElement = opts.domElement
  } else if (opts.domElement instanceof Function) {
    domElement = opts.domElement()
  } else if (opts.domElement) {
    domElement = document.querySelector(opts.domElement)
  }

  if (!domElement) {
    domElement = document.getElementById(htmlId)
  }
  if (!domElement) {
    domElement = document.createElement('div')
    document.body.appendChild(domElement)
  }

  if (!domElement.id) {
    domElement.id = htmlId
  }

  return domElement
}
