import { SHELL_APP_PREFIX } from '../constants'
import type { GoToExperienceConfigWithRootRoutes } from '../models'
import {
  CALENDAR_SETTINGS_ROUTE,
  APPEARANCE_SETTINGS_ROUTE,
  SHELL_SETTINGS_APP_LAUNCH_ROUTE,
  NOTIFICATIONS_SETTINGS_ROUTE,
  LANGUAGE_SETTINGS_ROUTE,
  KEYBOARD_SHORTCUT_SETTINGS_ROUTE,
} from '../../common/routes'

import GoToAppLaunchSettings from '../../packages/app-launch-settings/goto-app-launch-settings'
import GoToCalendarSettings from '../../packages/calendar/goto-calendar-settings'
import GoToAppearanceSettings from '../../packages/appearance/goto-appearance-settings'
import GoToKeyboardShortcutSettings from '../../packages/keyboard-shortcut/goto-keyboard-shortcut-settings'
import GoToNotificationsSettings from '../../packages/notifications-settings/goto-notifications-settings'
import GoToLanguageSettingsPage from '../../packages/language-settings/goto-language-settings'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import GotoHotkeySettings from '../../packages/hotkey/goto-hotkey-settings'

export const gotoAppLaunchSettingsExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: GoToAppLaunchSettings,
  name: `${SHELL_APP_PREFIX}/app-launch`,
  rootRoutes: [SHELL_SETTINGS_APP_LAUNCH_ROUTE],
}

export const gotoCalendarExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: GoToCalendarSettings,
  name: `${SHELL_APP_PREFIX}/calendar`,
  rootRoutes: [CALENDAR_SETTINGS_ROUTE],
}

export const goToAppearanceExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: GoToAppearanceSettings,
  name: `${SHELL_APP_PREFIX}/appearance`,
  rootRoutes: [APPEARANCE_SETTINGS_ROUTE],
}

export const gotoKeyboardShortcutExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: () =>
    getFeatureFlagValue(FeatureFlagsVariations.SHELL_KEYBOARD_SHORTCUT_EDITING)
      ? Promise.resolve(GoToKeyboardShortcutSettings)
      : Promise.resolve(GotoHotkeySettings),
  name: `${SHELL_APP_PREFIX}/keyboard-shortcut`,
  rootRoutes: [KEYBOARD_SHORTCUT_SETTINGS_ROUTE],
}

export const gotoNotificationsSettingsExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: GoToNotificationsSettings,
  name: `${SHELL_APP_PREFIX}/notifications`,
  rootRoutes: [NOTIFICATIONS_SETTINGS_ROUTE],
}

export const gotoLanguageSettingsExperienceConfig: GoToExperienceConfigWithRootRoutes = {
  app: GoToLanguageSettingsPage,
  name: `${SHELL_APP_PREFIX}/language`,
  rootRoutes: [LANGUAGE_SETTINGS_ROUTE],
}
