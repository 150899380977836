import type { Application } from 'single-spa'
import type { IconDefinition } from '../extensions/icon-definition'
import type { ShellConfig } from '../core/models'
import type { Translatable } from './translate-helpers/types'
import type { GoToExperienceConfigBasic } from '../experiences'

// source: https://docs.microsoft.com/en-us/javascript/api/@azure/keyvault-certificates/requireatleastone?view=azure-node-latest
export type RequireAtLeastOne<T> = {
  readonly [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>
}[keyof T]

export enum NavigationLinkTarget {
  blank = '_blank',
  parent = '_parent',
  self = '_self',
  top = '_top',
}

/**
 * Attribute available for testable components only
 */
export interface Testable {
  readonly dataTest?: string
}

interface Navigable {
  /**
   * Route to redirect the user
   */
  readonly href: string
  /**
   * Name for the anchor's aria-label
   */
  readonly name: Translatable
}

export interface NavigationLink extends Navigable, Testable {
  /**
   * unique identifier
   */
  readonly id: string
  /**
   * A string or function that returns a Chameleon icon, supporting string for backwards
   * compat and/or convenience of the experience
   *
   * @param isActive boolean value to determine which icon to show, some experiences have
   * diff icon depending on state
   */
  readonly icon: ((isActive: boolean) => string) | IconDefinition
  /**
   * A function that returns a boolean to show the link or not.
   * This is run once and the result is stored.  User may have to refresh to update it.
   */
  readonly isVisible: GoToExperienceConfigBasic['initializeServices']
  /**
   * The value to put in the badge component
   */
  /**
   * Specify if the navigation should use shell navigation or the default browser one
   */
  readonly externalLink?: boolean
}

export interface SettingsInfo {
  /**
   * The name of the accordion header if the experience has multiple settings
   */
  readonly accordionName?: Translatable
  /**
   * The icon representing the setting or group of settings of an exeperience
   */
  readonly settingsIcon: ((isActive: boolean) => string) | IconDefinition
  /**
   * A function that returns a boolean to show the link or not.
   * This is run once and the result is stored.  User may have to refresh to update it.
   */
  readonly isVisible: GoToExperienceConfigBasic['initializeServices']
  /**
   * The list of settings links passed by an experience
   */
  // eslint-disable-next-line functional/prefer-readonly-type
  readonly settingsLinksInfo: Navigable[]
}

/**
 * Interface defining an Action.
 * Actions can be used to build menus
 */
export interface ShellAction {
  /**
   * Function that will be called when the action is executed
   */
  readonly executeFunction?: () => void
  /**
   * labelKey Key used to get the localized label
   */
  readonly labelKey?: string
  /**
   * Children actions that will be displayed as a submenu
   */
  readonly children?: readonly ShellAction[]
}

export interface CustomProps {
  readonly links?: readonly NavigationLink[]
  readonly settingLinks?: SettingsInfo
  readonly shellConfig?: ShellConfig
}

export interface GoToSettingElementConfig {
  /**
   * The name of the accordion heading if the experience has multiple settings
   */
  readonly accordionName?: string
  /**
   * @deprecated
   * A function that returns a boolean to show the link or not
   */
  readonly isVisible: () => Promise<boolean>
  /**
   * A string or function that returns a Chameleon icon, supporting string for backwards
   * compat and/or convenience of the experience
   *
   * @param isActive boolean value to determine which icon to show, some experiences have
   * diff icon depending on state
   */
  readonly icon: ((isActive: boolean) => string) | string
  /**
   * An array of settings provided by the experiences
   */
  readonly settings: readonly GotoSubRouteSettings[]
}

/**
 * In order to register a specific setting, an experience must provide the following information
 */
export interface GotoSubRouteSettings {
  /**
   * URL of the setting
   */
  readonly href: string
  /**
   * Name of the setting
   */
  readonly name: string
  /**
   * A promise resolving in lifecycle functions that can lazily load a Setting Element, mount it when the user navigates to it and unmount it when the user navigates away from it
   */
  readonly app: SettingElement
}

export type SettingElement = Application
