import { isMobileUser } from '../../common/user-agent-helpers'
import { getExternalInterface } from '../../services/external-interface'
import { type ExtensionConfig } from '../extension-config'
import { addExtensionWithDependencies } from './utils'

export const filterForMobileIntegration = (extensionConfigs: ExtensionConfig[]) => {
  if (shouldFilterForMobile()) {
    const extensions = new Set<string>()

    extensionConfigs.forEach(extensionConfig => {
      if (extensionConfig.integrationConfig?.shouldLoadOnMobile) {
        addExtensionWithDependencies(extensions, extensionConfigs, extensionConfig)
      }
    })

    return extensionConfigs.filter(extensionConfig => extensions.has(extensionConfig.id))
  }

  return extensionConfigs
}

const shouldFilterForMobile = () => {
  const { isCompanion, isIntegration } = getExternalInterface()
  return isMobileUser() && (isIntegration || isCompanion)
}
