import { PrerequisiteRuleTypes, type ExtensionConfig } from '@goto/shell'
import { getFeatureFlag, getLaunchDarklyFlag } from '../helpers/shell-helpers'
import { LaunchDarklyVariations } from '../models/feature-flag.models'

enum Flags {
  GTC_VOICE_USE_UNLEASH = 'gtc-voice-use-unleash',
  GTC_VOICE_EXPERIENCE_VERSION = 'gtc-voice-experience-version-picker',
}

const canOverrideExperiencePath = (version: string) => version !== 'latest'

const getVoiceExperiencePath = () => {
  let version = 'latest'

  const useUnleash = getFeatureFlag(Flags.GTC_VOICE_USE_UNLEASH, false)
  if (useUnleash) {
    version = getFeatureFlag(Flags.GTC_VOICE_EXPERIENCE_VERSION, 'latest')
  } else {
    // Deprecated -- remove after unleash is fully enabled
    version = getLaunchDarklyFlag(LaunchDarklyVariations.GTC_VOICE_EXPERIENCE_VERSION) ?? 'latest'
  }

  // this string needs to exist on its own for feature branch deployments
  let path = '/experiences/voice/latest'

  if (canOverrideExperiencePath(version)) {
    path = `/experiences/voice/${version}`
  }
  return path
}

const prerequisiteEntitlements = 'jive || gotoconnect || grasshopper'

/**
 * Extension config
 */
const extensionConfig: ExtensionConfig = {
  id: '@voice',
  get url() {
    const path = getVoiceExperiencePath()
    return new URL(`${path}/extension.js`, window.location.origin)
  },
  dependencies: [],
  unguardedRoutes: [],
  esModule: true,
  prerequisites: {
    [PrerequisiteRuleTypes.ENTITLEMENTS]: prerequisiteEntitlements,
  },
  integrationConfig: {
    shouldLoadOnMobile: true,
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
    commandsToForwardToCompanion: [
      {
        namespace: 'Softphone',
        commandNames: ['showSoftphoneWidget'],
      },
      {
        namespace: 'VoiceIntegration',
        commandNames: [
          'reEmitLastCallsStateOnCompanion', 
          'reEmitLastSettingsStateOnCompanion', 
          'updateSettingsOnCompanion', 
          'updateExtensionOnCompanion'
        ],
      },
    ],
  },
}

const softphoneUIExtensionConfig: ExtensionConfig = {
  id: '@voice-softphone-ui',
  get url() {
    const path = getVoiceExperiencePath()
    return new URL(`${path}/extension-softphone-ui.js`, window.location.origin)
  },
  // When the UI is loaded in the dedicated softphone window with the
  // standaloneRoutes declared below, only the extensions declared in the
  // dependencies list will be loaded. Even though the UI depend on the
  // call system running either in the jiveweb or voice extension, we don't
  // declare the dependency here because it would reload it in the 2nd window.
  // Instead, the UI interact with the call system running in any window using
  // shell commands and event bus.
  dependencies: [],
  standaloneRoutes: ['^/softphone-ui'],
  unguardedRoutes: [],
  prerequisites: {
    [PrerequisiteRuleTypes.ENTITLEMENTS]: prerequisiteEntitlements,
  },
  esModule: true,
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default { mainExtension: extensionConfig, softphoneUIExtension: softphoneUIExtensionConfig }
