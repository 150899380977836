import { ExtensionConfig, PrerequisiteRuleTypes } from '@goto/shell'
import { getLocationPathname } from '../helpers/dom-helpers'
import { GTC_VERSION_FLAG_NAME, listUnguardedRoutes } from '../helpers/gtc-helpers'
import { getFeatureFlag } from '../helpers/shell-helpers'

/**
 * Extension config
 */
const extensionConfig: ExtensionConfig = {
  id: '@jive/web',
  get url() {
    const gtcVersion = getFeatureFlag(GTC_VERSION_FLAG_NAME, 'latest')
    return new URL(`/experiences/jiveweb/${gtcVersion}/extension.js`, location.origin)
  },
  prerequisites: {
    [PrerequisiteRuleTypes.ENTITLEMENTS]: 'jive || gotoconnect || grasshopper || g2m',
    shellFlags: 'gtc-voice-call-system!=default',
  },
  unguardedRoutes: listUnguardedRoutes(getLocationPathname()),
}

export default extensionConfig
