import { navigateToUrl } from '../../common/helpers'
import {
  encodeBase64ForUrl,
  getFromSessionStorage,
  getLocationHREF,
  getLocationObject,
  getLocationPathname,
  reloadPage,
  removeFromSessionStorage,
  setToSessionStorage,
} from '../../common/dom-helpers'
import { type RetryableRequestInit } from '../../common/fetch-utils'
import { ROOT_ROUTE, SEARCH_ROUTE } from '../../common/routes'
import { getShellApiInstance } from '../../common/shell-api-helpers/shell-api-instance'
import { SessionStorageKeys } from '../../environments'
import { HttpResponseError } from '../../http-utils'
import { authenticatedFetch } from '../../services/auth/authenticatedFetch'
import { type CommandDefinition } from '../../services/namespaces/models'
import { executeCommand } from '../../services/namespaces/utils'
import type {
  GlobalSearchApiError,
  GlobalSearchResultItem,
  GlobalSearchResultItemJSON,
  GlobalSearchQueryUI,
  GlobalSearchResponse,
  GlobalSearchResponseJSON,
  GlobalSearchResultUI,
} from './global-search-models'

const EMPTY_OPTIMIZED_QUERY = { q: '' }
const GLOBAL_SEARCH_CACHE_NAME = 'globalSearch'
const getUserPreferences = () => getShellApiInstance().userPreferences

export interface CacheConfig {
  /**
   * The key associated with the request to check in the cache
   */
  readonly requestKey: string
  /**
   * How long (in milliseconds) the request should be cached
   */
  readonly ttl: number
}

export const getSearchHistory = async (): Promise<readonly GlobalSearchQueryUI[]> => {
  const history =
    (await getUserPreferences()?.getPreference<string[], OptimizedSearchQueryUI[]>('search-history', [])) ?? []
  return history.reduce((queries: GlobalSearchQueryUI[], optimizedQuery: OptimizedSearchQueryUI) => {
    const query = convertToGlobalSearchQueryUI(optimizedQuery)
    if (query) {
      queries.push(query)
    }
    return queries
  }, [])
}

interface OptimizedSearchQueryUI {
  readonly q: string
}

const convertToOptimizedSearchQueryUI = (searchQuery: GlobalSearchQueryUI): OptimizedSearchQueryUI => ({
  q: searchQuery.query,
})

const convertToGlobalSearchQueryUI = (searchQuery: OptimizedSearchQueryUI): GlobalSearchQueryUI => ({
  query: searchQuery.q,
})

export const searchQueryToBase64 = (searchQuery: GlobalSearchQueryUI): string => {
  const optimized = convertToOptimizedSearchQueryUI(searchQuery)
  const str = JSON.stringify(optimized)
  return btoa(encodeURIComponent(str))
}

export const base64ToSearchQuery = (base64: string): GlobalSearchQueryUI | undefined => {
  try {
    const str = decodeURIComponent(atob(base64))
    const query = JSON.parse(str)
    return convertToGlobalSearchQueryUI(query)
  } catch (e) {
    return undefined
  }
}

export const getCurrentSearchQuery = (): GlobalSearchQueryUI =>
  convertToGlobalSearchQueryUI(getLocationObject<OptimizedSearchQueryUI>('search') ?? EMPTY_OPTIMIZED_QUERY)

const removeFromSearchHistoryArray = (
  searchQuery: GlobalSearchQueryUI,
  // eslint-disable-next-line functional/prefer-readonly-type
  searchHistory: GlobalSearchQueryUI[],
) => {
  const index = searchHistory.findIndex(query => searchQueryToBase64(searchQuery) === searchQueryToBase64(query))
  if (index >= 0) {
    searchHistory.splice(index, 1)
  }
}

export const addToSearchHistory = async (searchQuery: GlobalSearchQueryUI) => {
  const userPreferences = getUserPreferences()
  const searchHistory = [...(await getSearchHistory())]
  removeFromSearchHistoryArray(searchQuery, searchHistory)
  searchHistory.unshift(searchQuery)
  userPreferences?.setPreference(
    'search-history',
    searchHistory.map(query => convertToOptimizedSearchQueryUI(query)),
  )
}

export const removeFromSearchHistory = async (searchQuery: GlobalSearchQueryUI) => {
  const userPreferences = getUserPreferences()
  const searchHistory = [...(await getSearchHistory())]
  removeFromSearchHistoryArray(searchQuery, searchHistory)
  userPreferences?.setPreference(
    'search-history',
    searchHistory.map(query => convertToOptimizedSearchQueryUI(query)),
  )
}

export const navigateToSearchPage = (searchQuery: GlobalSearchQueryUI) => {
  if (getLocationHREF().indexOf(SEARCH_ROUTE) === -1) {
    setToSessionStorage(SessionStorageKeys.pathBeforeNavigationToSearchPage, getLocationPathname())
  }
  navigateToUrl(`${SEARCH_ROUTE}/${encodeBase64ForUrl(searchQueryToBase64(searchQuery))}`)
}

export const closeSearchPage = () => {
  const pathBeforeNavigationToSearchPage = getFromSessionStorage(SessionStorageKeys.pathBeforeNavigationToSearchPage)
  if (pathBeforeNavigationToSearchPage) {
    removeFromSessionStorage(SessionStorageKeys.pathBeforeNavigationToSearchPage)
    navigateToUrl(pathBeforeNavigationToSearchPage)
  } else {
    // If the user navigated directly to the search page and closed it, we should navigate to the root route and reload to force the default route to be loaded
    navigateToUrl(ROOT_ROUTE)
    reloadPage()
  }
}

/**
 * Loops over the response items and converts the data string into an object
 * @param response - the raw response from the global search API
 * @returns response with items array containing data as a GlobalSearchResultItem
 */
export const parseResultData = (response: GlobalSearchResponseJSON | undefined): GlobalSearchResponse | undefined => {
  if (response) {
    return {
      ...response,
      items: response.items.map((item: GlobalSearchResultItemJSON) => ({
        ...item,
        data: JSON.parse(item.data),
      })) as GlobalSearchResultItem[],
    } as GlobalSearchResponse
  }
  return response
}

/**
 * Logs the error received by the API if the response is not ok
 * @param response API Response
 * @returns Returns the HTTP Response if the response is ok
 */
export const handleApiResponse = async (response: Response | undefined): Promise<Response | undefined> => {
  if (response?.ok) {
    return response
  } else {
    const { status = 412, statusText = 'Precondition Failed', body = null } = response ?? {}
    const responseJson = (await response?.json()) as GlobalSearchApiError
    const message = responseJson?.message ?? 'Precondition Failed'
    throw new HttpResponseError({ message, response, status, statusText, body })
  }
}

export const handle504Error = (error: any) =>
  error instanceof TypeError && error.message === 'Failed to Fetch' ? { status: 504 } : Promise.reject(error)

export const executeSearchResultDefaultActionCommand = (searchResult: GlobalSearchResultUI, searchString: string) => {
  if (searchResult.defaultAction?.command) {
    executeGlobalSearchCommand(searchResult.defaultAction.command, searchString, searchResult.originalResult.data)
  }
}

/**
 * Execute a given command for global search context
 * @param command Command to execute
 * @param searchString searched string (or query)
 * @param resultItem search result item
 */
export const executeGlobalSearchCommand = (
  command: CommandDefinition,
  searchString: string,
  resultItemData?: unknown,
) => {
  executeCommand(command, searchString, resultItemData)
}

let currentlyCachedRequestKey = ''

export const fetchSearchWithCache = async <T = unknown>(
  apiUrl: string,
  init: RetryableRequestInit,
  cacheConfig: CacheConfig,
): Promise<T | undefined> => {
  const currentTime = new Date().getTime()
  const defaultCacheResetTime = cacheConfig.ttl + 1 //A fraction over configured cache ttl

  const cache = await window.caches.open(GLOBAL_SEARCH_CACHE_NAME)

  if (currentlyCachedRequestKey === cacheConfig.requestKey) {
    const cachedResponse = await cache.match(cacheConfig.requestKey)
    const fallbackTime = (currentTime - defaultCacheResetTime).toString()
    const previouslyCachedTime = cachedResponse?.headers?.get('X-Cache-Meta') ?? fallbackTime
    const cachedTimestamp = parseInt(previouslyCachedTime, 10)

    if (cachedResponse && currentTime - cachedTimestamp < cacheConfig.ttl) {
      return await cachedResponse.json() // Return cached data
    }
  } else {
    await cache.delete(currentlyCachedRequestKey) // Remove previously cached request
  }

  const freshResponse = await authenticatedFetch<T>(apiUrl, init).then(handleApiResponse)

  if (freshResponse) {
    const clonedResponse = freshResponse.clone()
    const updatedCacheResponse = new Response(clonedResponse.body, {
      headers: {
        ...clonedResponse.headers,
        'X-Cache-Meta': currentTime.toString(),
      },
    })
    cache.put(cacheConfig.requestKey, updatedCacheResponse)
    currentlyCachedRequestKey = cacheConfig.requestKey
    return await freshResponse.json()
  }
}

/**
 * This function tries to combine as much as possible the phone numbers parts
 * ie: user typing "Test 44 55 66 #77" would get "Test 44556677 as a result"
 * @param str Input string
 * @returns string with phone number parts combined
 */
export const combinePhoneNumberParts = (str: string): string => {
  const trimSpecialCharacters = (searchString: string) => searchString.replace(/[!$%^&*()_+]/g, '')
  const cleanupPhoneNumber = (searchString: string) =>
    searchString.replace(/(^|[0-9]*)(extension:?=?|;?ext=?.?:?|x)([0-9 ]*)/gi, (match, g1, _g2, g3) =>
      g1 || g3 ? `${g1}#${g3}` : match,
    )
  const isolatePhoneParts = (searchString: string) =>
    searchString.replace(
      /(^|\s)([0-9+()# -.]+)($|\s)/g,
      (_match, g1, g2, g3) => `${g1}${g2.replace(/[+.() -]+/g, '')}${g3}`,
    )
  return trimSpecialCharacters(isolatePhoneParts(cleanupPhoneNumber(str)))
}

/**
 * Converts a search string to a list of keywords
 * @param searchString search string
 * @returns kewords array
 */
export const getKeywordsFromSearchString = (str: string): string[] => {
  const query = combinePhoneNumberParts(str)
  return query.split(' ')
}
