/**
 * Base interface for Extensible Object Model object (EOM object)
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EOMInterface {}

/**
 * Interface defining identifier of Extensible Object Model object (EOM object)
 */
export interface EOMInterfaceIdentifier {
  readonly uid: string
}

/**
 * Interface defining Extensible Object Model object (EOM object)
 */
export interface EOMUnknown {
  /**
   * Queries an EOM object for a pointer to one of its interface implementation.
   * The interface is identified by a uid or a schema containing the uid that can be used to validate the model.
   * If the EOM object implements the interface, then it returns a pointer to that interface' implementation
   * @param intf uid or a schema object containing the uid ie: { uid: 'my-unique-id', myStringAttribute: '', myNumberAttribute: 0, myFunction: () => {} }
   * @returns pointer to the implementation of the interface.
   */
  queryInterface<I extends EOMInterface>(intf: string | (I & EOMInterfaceIdentifier)): I

  /**
   * Validates if an EOM object implements or not an interface using uid or schema.
   * @param intf uid or a schema object containing the uid ie: { uid: 'my-unique-id', myStringAttribute: '', myNumberAttribute: 0, myFunction: () => {} }
   * @returns True if interface is implemented
   */
  supportsInterface<I extends EOMInterface>(intf: string | (I & EOMInterfaceIdentifier)): boolean
}

const toId = (intf: string | EOMInterfaceIdentifier) => (typeof intf === 'string' ? intf : intf.uid)

export class EOMObject implements EOMUnknown {
  private registry: Map<string, EOMInterface> = new Map()

  protected implementInterface<I extends EOMInterface>(intf: string | (EOMInterfaceIdentifier & I), impl: I) {
    this.registry.set(toId(intf), impl)
  }

  queryInterface<I extends EOMInterface>(intf: string | (EOMInterfaceIdentifier & I)): I {
    const id = toId(intf)
    return this.registry.get(id) as I
  }

  supportsInterface<I extends EOMInterface>(intf: string | (EOMInterfaceIdentifier & I)): boolean {
    return this.registry.has(toId(intf))
  }
}
