import { LitElement, html, state } from 'lit-element'
import appLaunchSettingsStyles from './app-launch-settings.styles.scss'
import { t } from '../../directives/translate'
import { containerAPI } from '../../services/container'
import { Container } from '@getgo/container-client'
import type { SwitchComponent } from '@getgo/chameleon-web'
import { isContainer } from '../../services/container/helpers'

export const setLaunchContainerAtStartup = async (newSwitchValue: boolean) => {
  await containerAPI.containerSettings.setOpenAtLogin?.(newSwitchValue)
}

export class GoToAppLaunchSettings extends LitElement {
  static readonly tagName = 'goto-app-launch-settings'

  @state() private isOpenContainerAtStartupDisabled = true
  @state() private isOpenContainerAtStartupSelected = false

  static get styles() {
    return appLaunchSettingsStyles
  }

  connectedCallback() {
    super.connectedCallback()
    this.initValues()
  }

  firstUpdated() {
    this.addChangeEventListener()
    this.setToggleSwitch(this.isOpenContainerAtStartupSelected)
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    this.getToggleSwitch().removeEventListener('change', this.handleChangeEvent)
  }

  async initValues() {
    this.isOpenContainerAtStartupDisabled = !isContainer() || !Container.isPluginAvailable('ContainerSettings')
    this.isOpenContainerAtStartupSelected = (await containerAPI.containerSettings.getOpenAtLogin?.()) ?? false
    this.setToggleSwitch(this.isOpenContainerAtStartupSelected)
  }

  private addChangeEventListener() {
    const toggleSwitch = this.getToggleSwitch()
    toggleSwitch.addEventListener('change', this.handleChangeEvent)
  }

  private getToggleSwitch() {
    return this.shadowRoot?.querySelector('.container-startup-switch') as SwitchComponent
  }

  private handleChangeEvent(event: Event) {
    const target = event.target as SwitchComponent
    setLaunchContainerAtStartup(target.checked)
    this.isOpenContainerAtStartupSelected = target.checked
  }

  private setToggleSwitch(newSwitchValue: boolean) {
    const toggleSwitch = this.getToggleSwitch()
    toggleSwitch.checked = newSwitchValue
  }

  render() {
    return html`
      <chameleon-typography class="settings-header" tag="h1" variant="heading-large"
        >${t('App launch')}
      </chameleon-typography>
      <chameleon-typography class="header-description" tag="p" variant="body-medium"
        >${t('Configure app launch settings')}
      </chameleon-typography>
      <div class="container-startup-content">
        <chameleon-typography tag="p" variant="heading-small">${t('Desktop')} </chameleon-typography>
        <div class="container-startup-switch-block">
          <div class="container-startup-text-block">
            <chameleon-typography class="content-headers" tag="p" variant="body-large" id="switch-label-id"
              >${t('Start GoTo Desktop automatically at launch')}
            </chameleon-typography>
            <chameleon-typography class="content-description" tag="p" variant="caption-default-01" color="text-02">
              ${t(
                'Turning the setting on will automatically launch GoTo Desktop application when your computer starts.',
              )}
            </chameleon-typography>
          </div>
          <chameleon-switch
            class="container-startup-switch"
            ?disabled=${this.isOpenContainerAtStartupDisabled}
            label-id="switch-label-id"
          ></chameleon-switch>
        </div>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToAppLaunchSettings.tagName]: GoToAppLaunchSettings
  }
}
