import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'

export interface Hotkey {
  /**
   * The hotkey string to bind for Mac OS users
   */
  readonly macOSKey: readonly MacOSKeys[]
  /**
   * The hotkey string to bind for Windows and Linux users
   */
  readonly otherOSKey: readonly OtherOSKeys[]
  /**
   * The description label of the hotkey, for use in the settings page.
   */
  readonly label: string
  /**
   * The string which contains the translated hotkey label.
   */
  readonly translatedLabel?: string
}

type OtherOSKeys = UniversalKeys | DirectionKeys | otherOSModifiers | universalModifiers
type MacOSKeys = UniversalKeys | DirectionKeys | macOSModifiers | universalModifiers

export interface ParsedHotkey {
  readonly translatedLabel: string
  readonly keys: readonly MacOSKeys[] | readonly OtherOSKeys[]
  readonly label: string
}

export enum HotkeyLabels {
  APP_SWITCHER = 'App Switcher',
  BACK_HISTORY = 'Back in history',
  CHANGE_VIDEO_FOCUS = 'Change video focus',
  CONTACTS = 'Contacts',
  CALL = 'Dialer',
  FORWARD_HISTORY = 'Forward in history',
  MEETINGS = 'Meetings',
  MUTE_UNMUTE = 'Mute or unmute',
  TEXT = 'New Message',
  RECENT_ACTIVITY = 'Recents',
  SETTINGS = 'Settings',
  START_REMOVE_VIDEO = 'Start or remove video',
  TOGGLE_LEFT_PANEL = 'Toggle left panel',
  KEYBOARD_SHORTCUTS = 'Keyboard shortcuts',
  VOICEMAIL = 'Voicemail',
}

type macOSModifiers = 'command' | 'control'

type otherOSModifiers = 'ctrl' | 'alt'

type universalModifiers = 'shift'

type UniversalKeys =
  | 'a'
  | 'c'
  | 'd'
  | 'h'
  | '['
  | ']'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | 'k'
  | 'm'
  | 's'
  | '/'
  | '\\'

type DirectionKeys = '↑' | '↓' | '←' | '→'

export const GlobalNavigationHotkeys: GlobalHotkeysMap = {
  GLOBAL_A: {
    CALL: {
      macOSKey: ['control', 'shift', '1'],
      otherOSKey: ['alt', 'shift', '1'],
      label: asTranslationKey('Dialer'),
    } as Hotkey,
    TEXT: {
      macOSKey: ['control', 'shift', '2'],
      otherOSKey: ['alt', 'shift', '2'],
      label: asTranslationKey('New Message'),
    } as Hotkey,
  },
  GLOBAL_B: {
    SETTINGS: {
      macOSKey: ['control', 's'],
      otherOSKey: ['alt', 's'],
      label: asTranslationKey('Settings'),
    } as Hotkey,
    APP_SWITCHER: {
      macOSKey: ['control', 'shift', 's'],
      otherOSKey: ['alt', 'shift', 's'],
      label: asTranslationKey('App Switcher'),
    } as Hotkey,
    HISTORY_BACK: {
      macOSKey: ['control', '['],
      otherOSKey: ['alt', '['],
      label: asTranslationKey('Back in history'),
    } as Hotkey,
    HISTORY_FORWARD: {
      macOSKey: ['control', ']'],
      otherOSKey: ['alt', ']'],
      label: asTranslationKey('Forward in history'),
    } as Hotkey,
    LEFT_PANEL_SLIDER: {
      macOSKey: ['control', '\\'],
      otherOSKey: ['alt', '\\'],
      label: asTranslationKey('Toggle left panel'),
    } as Hotkey,
    HOTKEY_SETTINGS: {
      macOSKey: ['command', '/'],
      otherOSKey: ['alt', '/'],
      label: asTranslationKey('Keyboard shortcuts'),
    } as Hotkey,
  },
} as const

export const EntitledNavigationHotkeys: EntitledHotkeysMap = {
  JIVE_ENTITLED: {
    VOICEMAILS: {
      macOSKey: ['control', 'shift', '3'],
      otherOSKey: ['alt', 'shift', '3'],
      label: asTranslationKey('Voicemail'),
    } as Hotkey,
    CONTACTS: {
      macOSKey: ['control', 'shift', '4'],
      otherOSKey: ['alt', 'shift', '4'],
      label: asTranslationKey('Contacts'),
    } as Hotkey,
    RECENT_ACTIVITY: {
      macOSKey: ['control', 'shift', '5'],
      otherOSKey: ['alt', 'shift', '5'],
      label: asTranslationKey('Recents'),
    } as Hotkey,
  },
  GOTOCONNECT_ENTITLED: {
    MEETINGS: {
      macOSKey: ['control', 'shift', '3'],
      otherOSKey: ['alt', 'shift', '3'],
      label: asTranslationKey('Meetings'),
    } as Hotkey,
    VOICEMAILS: {
      macOSKey: ['control', 'shift', '4'],
      otherOSKey: ['alt', 'shift', '4'],
      label: asTranslationKey('Voicemail'),
    } as Hotkey,
    CONTACTS: {
      macOSKey: ['control', 'shift', '5'],
      otherOSKey: ['alt', 'shift', '5'],
      label: asTranslationKey('Contacts'),
    } as Hotkey,
    RECENT_ACTIVITY: {
      macOSKey: ['control', 'shift', '6'],
      otherOSKey: ['alt', 'shift', '6'],
      label: asTranslationKey('Recents'),
    } as Hotkey,
  },
} as const

export const EntitledCallAndMeetingHotkeys: EntitledHotkeysMap = {
  JIVE_ENTITLED: {
    TOGGLE_MUTE: {
      macOSKey: ['command', 'shift', 'm'],
      otherOSKey: ['ctrl', 'shift', 'm'],
      label: asTranslationKey('Mute or unmute'),
    } as Hotkey,
  },

  GOTOCONNECT_ENTITLED: {
    TOGGLE_MUTE: {
      macOSKey: ['command', 'shift', 'm'],
      otherOSKey: ['ctrl', 'shift', 'm'],
      label: asTranslationKey('Mute or unmute'),
    } as Hotkey,
    TOGGLE_WEBCAM: {
      macOSKey: ['command', 'shift', 'k'],
      otherOSKey: ['ctrl', 'shift', 'k'],
      label: asTranslationKey('Start or remove video'),
    } as Hotkey,
    VIDEO_FOCUS_LEFT: {
      macOSKey: ['shift', '←'],
      otherOSKey: ['shift', '←'],
      label: asTranslationKey('Change video focus'),
    } as Hotkey,
    VIDEO_FOCUS_UP: {
      macOSKey: ['shift', '↑'],
      otherOSKey: ['shift', '↑'],
      label: asTranslationKey('or'),
    } as Hotkey,
    VIDEO_FOCUS_DOWN: {
      macOSKey: ['shift', '↓'],
      otherOSKey: ['shift', '↓'],
      label: asTranslationKey('or'),
    } as Hotkey,
    VIDEO_FOCUS_RIGHT: {
      macOSKey: ['shift', '→'],
      otherOSKey: ['shift', '→'],
      label: asTranslationKey('or'),
    } as Hotkey,
  },
} as const
export interface HotkeySection {
  readonly label: string
  readonly keys: readonly Hotkey[]
}

const globalHotkeysSection = {
  GLOBAL_A: 'GLOBAL_A',
  GLOBAL_B: 'GLOBAL_B',
}

const entitledHotkeysSection = {
  JIVE_ENTITLED: 'JIVE_ENTITLED',
  GOTOCONNECT_ENTITLED: 'GOTOCONNECT_ENTITLED:',
}

type GlobalHotkeysMap = {
  readonly [k in keyof typeof globalHotkeysSection]: {
    readonly [k: string]: Hotkey
  }
}
type EntitledHotkeysMap = {
  readonly [k in keyof typeof entitledHotkeysSection]: {
    readonly [k: string]: Hotkey
  }
}
