import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getAccounts, getCurrentAccountSettings } from '../../common/user-helpers'
import type { ShellUserAccountSettings, ShellUserAuthorityContextPermissions } from '../../core/models'
import { getFeatureFlagValue, getLaunchDarklyFlag } from '../../services/feature-flags'
import type { ExtensionConfig, Prerequisites } from '../extension-config'
import { PrerequisiteRuleTypes } from '../extension-config'
import type { ValueResolver } from '../prerequisites/name-rule'
import { isRuleValid } from '../prerequisites/rule'

export const ENTITLEMENTS_VALUE_RESOLVER = (name: string) =>
  getShellApiInstance().user.entitlements.includes(name) ?? false

export const ROLES_VALUE_RESOLVER = (name: string) => getShellApiInstance().user.roles.includes(name) ?? false

export const PBX_FLAGS_VALUE_RESOLVER = (name: string) => getShellApiInstance().context.pbx?.flags?.[name] ?? false

export const CONTACT_CENTER_FLAGS_VALUE_RESOLVER = (name: string) =>
  getShellApiInstance().user.hasPermissions([name as ShellUserAuthorityContextPermissions]) ?? false

export const SHELL_FEATURE_FLAGS_VALUE_RESOLVER = (name: any) => {
  let resolveValue
  let featureFlagValue = getFeatureFlagValue(name)

  if (featureFlagValue === undefined) {
    featureFlagValue = getLaunchDarklyFlag(name)
  }

  if (
    typeof featureFlagValue === 'string' ||
    typeof featureFlagValue === 'number' ||
    typeof featureFlagValue === 'boolean'
  ) {
    resolveValue = featureFlagValue
  } else if (typeof featureFlagValue === 'object') {
    resolveValue = true
  }
  return resolveValue ?? false
}

export const ACCOUNT_SETTINGS_VALUE_RESOLVER = (name: string) => {
  const [target, ...rest] = name.split('.')
  const flagName = rest.join('.')

  const accountSettings = getCurrentAccountSettings()
  //If user has one account and flag is set to true
  if (isFlagNameDefined(accountSettings, target, flagName)) {
    return !!accountSettings[target][flagName]
  }

  //If user has multiple accounts and any account has the flag set to true
  let result = false
  result = getAccounts().some(account => {
    if (isFlagNameDefined(account.settings, target, flagName)) {
      return account.settings[target][flagName] === true
    }
  })

  return result
}

function isFlagNameDefined(
  settings: ShellUserAccountSettings | undefined,
  objectName: string,
  flagName: string,
): settings is ShellUserAccountSettings {
  return !!(settings?.[objectName] && settings[objectName][flagName] !== null)
}

/**
 * Takes the array of Extension Configs and filters them by their prerequisite rules
 * @param extensionConfigs the extensionConfigs to be loaded in the App
 * @returns a filtered array of extension configs
 */
export const filterExtensionsByPrerequisites = (
  extensionConfigs: readonly ExtensionConfig[],
): readonly ExtensionConfig[] =>
  extensionConfigs.filter(extensionConfig => {
    if (!extensionConfig.prerequisites) {
      return extensionConfig
    }
    return resolveExtensionPrerequisiteRules(extensionConfig.prerequisites)
  })

/**
 * Resolves each prerequisite rule that is required for an extension to be loaded by
 * validating it against its corresponding Value Resolver
 * @returns true unless the given rule is not satisfied by the Value Resolver
 */
export const resolveExtensionPrerequisiteRules = (prerequisites: Prerequisites): boolean => {
  let key: keyof Prerequisites
  for (key in prerequisites) {
    const rule = prerequisites[key]
    const valueResolver = getValueResolverForPrerequisiteKey(key)

    if (rule && !isRuleValid(rule, valueResolver)) {
      return false
    }
  }
  return true
}

/**
 * A collection of functions which provide the data to resolve the rules for each prerequisite
 * @param key the type of prerequisite rule that the Value Resolver function is used for
 * @returns A Value Resolver function which is used to resolve a prerequisite rule
 */
const getValueResolverForPrerequisiteKey = (key: PrerequisiteRuleTypes): ValueResolver => {
  switch (key) {
    case PrerequisiteRuleTypes.ENTITLEMENTS:
      return ENTITLEMENTS_VALUE_RESOLVER

    case PrerequisiteRuleTypes.ROLES:
      return ROLES_VALUE_RESOLVER

    case PrerequisiteRuleTypes.PBX_FLAGS:
      return PBX_FLAGS_VALUE_RESOLVER

    case PrerequisiteRuleTypes.CONTACT_CENTER_FLAGS:
      return CONTACT_CENTER_FLAGS_VALUE_RESOLVER

    case PrerequisiteRuleTypes.SHELL_FEATURE_FLAGS:
      return SHELL_FEATURE_FLAGS_VALUE_RESOLVER

    case PrerequisiteRuleTypes.ACCOUNT_SETTINGS:
      return ACCOUNT_SETTINGS_VALUE_RESOLVER
  }
}
