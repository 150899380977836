import type { RegisterApplicationConfig } from 'single-spa'
import { getMountedApps, pathToActiveWhen } from 'single-spa'
import type { GoToExperienceConfig } from './models'
import * as internalExperiences from './configs/internals'
import { BASEURL_AUTH } from '../environments'

export const BACKGROUND_APPLICATIONS = ['@jive/web/Background/GTC']

/**
 * Get the name of the internal experiences
 * @returns
 */
export const getInternalExperiencesName = () => Object.values(internalExperiences).map(experience => experience.name)

/**
 * Filters out internal shell applications (e.g. banners) and background applications (e.g. legacy gtc apps)
 * @param apps - the array of applications to be filtered
 * @returns an array of filtered application names
 */
export const filterInternalHiddenApps = (apps: readonly string[]): readonly string[] => {
  const internalExperiencesName = getInternalExperiencesName()
  return apps.filter((app: string) => !internalExperiencesName.includes(app) && !BACKGROUND_APPLICATIONS.includes(app))
}

/**
 *
 * @returns list of current mounted apps
 */
export const getMountedShellApps = () => {
  const mountedApps = getMountedApps()
  return filterInternalHiddenApps(mountedApps)
}

/**
 * Check if a main experience is mounted.
 * A main experience is an experience that is not define as a Shell internal experiences and should be the main UI
 * @returns
 */
export const isMainExperienceMounted = () => {
  const mainExperiencesMounted = getMountedShellApps()
  return mainExperiencesMounted.length > 0
}

export const gotoExperienceConfigToSingleSpaConfig = (config: GoToExperienceConfig): RegisterApplicationConfig => {
  const { app, name, activeWhen, customProps, rootRoutes, rootRoute } = config

  let routeActiveWhen: (location: Location) => boolean

  if (activeWhen) {
    routeActiveWhen = (location: Location) => !location.pathname.includes(BASEURL_AUTH.redirect) && activeWhen()
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  if (rootRoutes?.some(route => route.includes(BASEURL_AUTH.redirect)) || rootRoute?.includes(BASEURL_AUTH.redirect)) {
    throw new Error(`Experience ${name} tried to load on ${BASEURL_AUTH.redirect}`)
  }

  return {
    app,
    name,
    customProps,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore "this is fine"
    activeWhen: routeActiveWhen ?? rootRoutes ?? pathToActiveWhen(rootRoute ?? ''), // TODO: https://jira.ops.expertcity.com/browse/SCORE-475 both should not be undefined at the same time
  }
}
