import { getToken } from './token'
import { refreshAuthentication } from './refresh'
import { HttpResponseError } from '../../http-utils'
import type { RetryableRequestInit } from '../../common/fetch-utils'
import { fetchRetry, retry } from '../../common/fetch-utils'
import type { Token } from '@getgo/auth-client'
import stackLogger from '../stack-logger'

const AUTHENTICATION_TIMEOUT = 30 * 1000 // 30 seconds

export interface TypedResponse<T = unknown> extends Response {
  /**
   * this will override `json` method from `Body` that is extended by `Response`
   * interface Body {
   *     json(): Promise<any>;
   * }
   */
  json<P = T>(): Promise<P>
}

// Utility function to get stack trace
const getStackTrace = () => {
  const stack = new Error().stack
  if (!stack) {
    return 'No stack trace available'
  }
  return stack.split('\n').slice(2).join('\n') // Ignore the first two lines (Error and this function)
}

export const retryGetToken = async () => {
  const maxNbTries = 2
  let token = getToken()
  let nbOfTries = 0

  while (!token && nbOfTries < maxNbTries) {
    nbOfTries++
    // Reminder: calling authenticatedFetch before the token is set will resolve the promise
    // to undefined, and not the expected typed object
    stackLogger.addEvent(`retryGetToken: Retry get token refreshAuthentication attempt ${nbOfTries}`) // NOSONAR
    await refreshAuthentication()
    token = getToken()
  }
  return token
}

const fetchAuth = async (token: Token, input: RequestInfo, init?: RetryableRequestInit): Promise<Response> => {
  const { token_type, access_token } = token
  const headers = new Headers(init?.headers)
  headers.set('Authorization', `${token_type} ${access_token}`)
  return await fetchRetry(input, {
    credentials: 'include',
    ...init,
    headers,
  })
}

export const authenticatedFetch = async <T = unknown>(
  input: RequestInfo,
  init?: RetryableRequestInit,
): Promise<TypedResponse<T>> => {
  // Log where authenticatedFetch is called
  stackLogger.addEvent(`authenticatedFetch with url: ${input} called from: ${getStackTrace()}`) // NOSONAR

  const token = await retryGetToken()
  if (!token?.access_token || !token.token_type) {
    stackLogger.addEvent('authenticatedFetch: Error no access token') // NOSONAR

    throw new Error('Could not fetch the token')
  }

  const timeoutInMS = init?.timeoutInMS ?? AUTHENTICATION_TIMEOUT

  const response = await fetchAuth(token, input, { ...init, timeoutInMS })
  const { body, status, statusText } = response
  // Authenticated Fetch failed, so redirect to login
  if (status === 401) {
    stackLogger.addEvent('authenticatedFetch: Error response 401') // NOSONAR

    await refreshAuthentication()
  } else if (status >= 400) {
    stackLogger.addEvent('authenticatedFetch: Error response 403') // NOSONAR
    throw new HttpResponseError({
      message: `${status}: ${statusText}`,
      response,
      status,
      statusText,
      body,
    })
  }
  return response
}

/**
 * Returns a response based on authenticated fetch with randomized delays with a baseline.
 * This is to avoid DDOSing then back-end when we are trying to reconnect to the websocket.
 *
 * @param input - The request info, usually containing the info for the websocket
 * @param init - The request initiation that for fetch retries
 * @param retryInterval - The baseline interval, default is 250. This interval is randomized and added to the baseline
 * @param maxRetry The maximum number of times the fetch is attempted, default is 2.
 * @returns The response or throws an error if the number of retries runs out
 */

export const authenticatedFetchWithRetries = async <T = unknown>(
  input: RequestInfo,
  init?: RetryableRequestInit,
  retryInterval = 250,
  maxRetry = 2,
): Promise<TypedResponse<T>> =>
  await retry(() => authenticatedFetch(input, init), {
    maxRetryNumber: maxRetry,
    retryAfterInMilliseconds: retryInterval,
    randomized: true,
    exponential: true,
  })
