import { LitElement, html, state } from 'lit-element'
import { SVG_SETTINGS_OUTLINED, SVG_SETTINGS_FILLED } from '@getgo/chameleon-icons'
import type { PopoverChangeEvent } from '@getgo/chameleon-web'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { t } from '../../directives/translate'
import { repeat } from 'lit-html/directives/repeat'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { SHELL_SETTINGS_ROUTE } from '../../common/routes'
import { navigateToUrl } from '../../common/helpers'
import styles from './settings-popover-menu.styles.scss'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import type { Testable } from '../../common'

interface MenuItem extends Testable {
  readonly href: string
  readonly label: string
}

export class GoToSettingsPopoverMenu extends LitElement {
  static readonly tagName = 'goto-settings-popover-menu'

  @state()
  private menuIsDisplayed = false

  static get styles() {
    return styles
  }

  private readonly menuItems: readonly MenuItem[] = [
    {
      href: SHELL_SETTINGS_ROUTE,
      label: asTranslationKey('Settings'),
      dataTest: 'navigate-to-settings',
    },
    {
      href: '/guide/welcome',
      label: asTranslationKey('Help'),
      dataTest: 'navigate-to-help',
    },
  ]

  private menuDisplayStateHasChanged(isOpen: boolean) {
    this.menuIsDisplayed = isOpen
  }

  private logout() {
    getShellApiInstance().auth.logout()
  }

  private renderMenuItem({ label: labelKey, href, dataTest }: MenuItem) {
    return html`
      <chameleon-menu-item
        data-test="${dataTest ? dataTest : ''}"
        @click=${() => {
          navigateToUrl(href)
        }}
      >
        ${t(labelKey)}
      </chameleon-menu-item>
    `
  }

  render() {
    return html`
      <chameleon-popover
        position="top-start"
        @popoverchange=${({ detail: { isOpen } }: PopoverChangeEvent) => this.menuDisplayStateHasChanged(isOpen)}
        menu
      >
        <chameleon-icon-button
          size="small"
          variant="secondary"
          slot="trigger"
          aria-label=${t('Settings')}
          class="menu-trigger"
        >
          <chameleon-svg data-test="${this.menuIsDisplayed ? 'menu-is-displayed' : 'menu-is-not-displayed'}">
            ${unsafeSVG(this.menuIsDisplayed ? SVG_SETTINGS_FILLED : SVG_SETTINGS_OUTLINED)}
          </chameleon-svg>
        </chameleon-icon-button>
        <div slot="content">
          <chameleon-menu>
            ${repeat(
              this.menuItems,
              ({ href }) => href,
              item => this.renderMenuItem(item),
            )}
            <chameleon-menu-item @click=${this.logout} data-test="sign-out"> ${t('Sign out')} </chameleon-menu-item>
          </chameleon-menu>
        </div>
      </chameleon-popover>
    `
  }
}
declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToSettingsPopoverMenu.tagName]: GoToSettingsPopoverMenu
  }
}
