import type { BannerVariant, ButtonSize, ButtonVariant } from '@getgo/chameleon-core'
import type { Testable } from '../../common'
/**
 * Represents a link that can be displayed within a banner notification.
 */
export interface BannerLink {
  /**
   * The text to display for the link.
   */
  readonly text: string

  /**
   * The URL to navigate to when the link is clicked.
   */
  readonly href?: string

  /**
   * A callback function to execute when the link is clicked.
   */
  readonly onClick?: () => void
}

export interface WindowReferencedNotification {
  /**
   * By default display Banner/SnackBar/Modal will be shown
   * on ALL windows/tabs.
   * Unless you specify a `target` of where you want it displayed.
   *
   * https://jira.ops.expertcity.com/browse/SCORE-1291
   */
  readonly target?: Window
}

/**
 * Represents a banner notification that can be displayed to the user.
 */
export interface BannerNotification extends WindowReferencedNotification {
  /* eslint-disable functional/prefer-readonly-type */
  /**
   * The unique identifier for the notification.
   * @remarks This property is optional.
   */
  id?: string
  /**
   * The description of the notification.
   */
  description: string
  /**
   * Whether the notification can be closed by the user.
   * @remarks This property is optional.
   */
  closable?: boolean
  /**
   * The variant of the banner notification.
   * @remarks This property is optional.
   */
  variant?: BannerVariant
  /**
   * The function to call when the notification is closed.
   * @remarks This property is optional.
   */
  onClose?: () => void
  /**
   * The icon to display with the notification.
   * @remarks This property is optional.
   */
  icon?: string
  /**
   * The link to navigate to when the notification is clicked.
   * @remarks This property is optional.
   */
  link?: BannerLink
  /* eslint-enable functional/prefer-readonly-type */
}

/**
 * Represents a banner notification with an ID.
 */
export interface BannerNotificationWithId extends BannerNotification {
  /**
   * The unique identifier for the notification.
   */
  readonly id: string
}

/**
 * Represents an action that can be taken in response to a SnackbarNotification.
 */
export interface SnackbarNotificationAction {
  /** The label to display for the action. */
  readonly label: string
  /** The function to call when the action is taken. */
  readonly handler: () => void
}

/**
 * @summary Snackbar notification data
 * @description
 *      Snackbar notification interface contains the following properties:
 *      id: Mandatory property. The unique id of the snackbar
 *      title: Mandatory property. Short message displayed to the user
 *      message: Optional property. Short message under the title
 *      progress: Optional property. The percentage of progress
 *      actions: Optional property. The possible feedback actions for the user
 */
export interface SnackbarNotification extends WindowReferencedNotification, Testable {
  // eslint-disable-next-line functional/prefer-readonly-type
  id: string
  readonly title: string
  readonly message?: string
  // eslint-disable-next-line functional/prefer-readonly-type
  progress?: number
  readonly actions?: readonly SnackbarNotificationAction[]
  /**
   * list of functions to cancel hiding after a few seconds.
   *
   * Since we might run the same widget on different window "realms", we get different
   * setTimeout Id per realm, so we need a place to store those "cancel tokens"
   */
  // eslint-disable-next-line functional/prefer-readonly-type
  timeoutCancelers?: (() => void)[]
}

/**
 * Represents the actions available for a modal notification.
 */
export interface ModalNotificationActions {
  /** The label for the action button. */
  readonly label: string
  /** The function to be executed when the action button is clicked. */
  readonly handler: () => void
  /** The variant of the action button. */
  readonly variant?: ButtonVariant
  /** The size of the action button. */
  readonly size?: ButtonSize
}

/**
 * Represents a modal notification that can be displayed in a window.
 */
export interface ModalNotification extends WindowReferencedNotification {
  /**
   * The unique identifier of the notification.
   */
  // eslint-disable-next-line functional/prefer-readonly-type
  id: string
  /**
   * The title of the notification.
   */
  readonly title: string
  /**
   * The content of the notification.
   */
  readonly content: string
  /**
   * The actions that can be taken in response to the notification.
   */
  readonly actions?: readonly ModalNotificationActions[]
  /**
   * Whether the notification can be closed by the user.
   */
  readonly closable?: boolean
}

/**
 * This is a temporary fix to allow banners with approved ids to be shown on the Shell.
 * This array is hierarchical, the banner whose id has the lowest index will be shown.
 */
export const approvedShellBannerIds = [
  'overrideBanner',
  'retry-login-container',
  'shellDisconnectionBanner',
  'phone-disconnected-error-id',
  'shellUnrecoverableErrorBanner',
  'shellSessionExpiredBanner',
  'direct-meeting-invite-declined-banner',
]
