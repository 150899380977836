import type { ExtensionConfig } from '@goto/shell'

const extensionConfig: ExtensionConfig = {
  id: '@goto/network-measurements',
  url: new URL('/experiences/networkmeasurements/extension.js', location.origin),
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
