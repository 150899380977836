import { html, LitElement, property } from 'lit-element'
import hotkeySettingsStyles from './hotkey-settings.styles.scss'
import { t } from '../../directives/translate'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import { hasGotoConnectEntitlement } from '../../services/identity/entitlement'
import { isMeetingsFlagEnabledInContextPBX, isStandardFlagEnabledInContextPBX } from '../../services/phone-system'
import type { Hotkey, HotkeySection } from './hotkey.models'
import { EntitledCallAndMeetingHotkeys, EntitledNavigationHotkeys, GlobalNavigationHotkeys } from './hotkey.models'
import { repeat } from 'lit-html/directives/repeat'

export class GoToHotkeySettings extends LitElement {
  static readonly tagName = 'goto-hotkey-settings'

  @property({ type: Array }) entitledNavigationHotkeys: Hotkey[] = []
  @property({ type: Array }) allNavigationHotkeys: Hotkey[] = []
  @property({ type: Array }) hotkeySections: HotkeySection[] = []
  @property({ type: Array }) callAndMeetingHotkeys: Hotkey[] = []
  @property({ type: String }) callsAndMeetingsLabel = ''

  static get styles() {
    return hotkeySettingsStyles
  }

  async connectedCallback() {
    super.connectedCallback()
    if (await this.shouldSeeGTCEntitledHotkeys()) {
      this.setEntitledNavigationKeys(EntitledNavigationHotkeys.GOTOCONNECT_ENTITLED)
      this.setCallAndMeetingKeys(EntitledCallAndMeetingHotkeys.GOTOCONNECT_ENTITLED)
      this.setCallsAndMeetingsLabel(asTranslationKey('Calls and meetings'))
    } else {
      this.setEntitledNavigationKeys(EntitledNavigationHotkeys.JIVE_ENTITLED)
      this.setCallAndMeetingKeys(EntitledCallAndMeetingHotkeys.JIVE_ENTITLED)
      this.setCallsAndMeetingsLabel(asTranslationKey('Calls'))
    }
    this.setAllNavigationKeys()
  }

  private async shouldSeeGTCEntitledHotkeys() {
    return (
      !(await isStandardFlagEnabledInContextPBX()) &&
      ((await isMeetingsFlagEnabledInContextPBX()) || hasGotoConnectEntitlement())
    )
  }

  private setEntitledNavigationKeys(entitledKeys: { [s: string]: Hotkey }) {
    this.entitledNavigationHotkeys = Object.values(entitledKeys)
  }

  private setCallAndMeetingKeys(entitledKeys: { [s: string]: Hotkey }) {
    this.callAndMeetingHotkeys = Object.values(entitledKeys)
  }

  private setAllNavigationKeys() {
    this.allNavigationHotkeys = Object.values(GlobalNavigationHotkeys.GLOBAL_A).concat(
      this.entitledNavigationHotkeys,
      Object.values(GlobalNavigationHotkeys.GLOBAL_B),
    )
  }

  private setCallsAndMeetingsLabel(label: string) {
    this.callsAndMeetingsLabel = label
  }

  private getHotkeySections(): HotkeySection[] {
    return [
      {
        label: asTranslationKey('Navigation'),
        keys: this.allNavigationHotkeys,
      },
      {
        label: this.callsAndMeetingsLabel,
        keys: this.callAndMeetingHotkeys,
      },
    ]
  }

  keyboardShortcutsDescripton = asTranslationKey(
    'Use these quick keystrokes to carry out common actions in your daily work',
  )

  render() {
    return html`
      <chameleon-typography class="hotkey-settings-header" tag="h1" variant="heading-large"
        >${t('Keyboard shortcuts')}</chameleon-typography
      >
      <chameleon-typography class="header-description" tag="p" variant="body-medium"
        >${t('Use these quick keystrokes to carry out common actions in your daily work')}</chameleon-typography
      >
      <div class="hotkey-settings">
        ${repeat(
          this.getHotkeySections(),
          section => section.label,
          section =>
            html` <goto-hotkey-section
              class="keyboard-shortcuts-section"
              .hotkeySectionLabel=${t(section.label)}
              .hotkeySectionList=${section.keys}
            ></goto-hotkey-section>`,
        )}
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToHotkeySettings.tagName]: GoToHotkeySettings
  }
}
