import { getChameleonWebVersion } from '../../common/chameleon-version'
import { getHostAppVersion } from '../../common/host-app-version'
import { GOTO_SHELL_VERSION } from '../../common/version'
import { getExtensionsManager } from '../../extensions/extensions-manager'
import type { ExtensionMetadata } from '../../extensions/metadata-definition'
import { type MetaServiceAPI } from './models'

export class MetaService implements MetaServiceAPI {
  getAppVersion(): string {
    return getHostAppVersion()
  }

  getShellVersion(): string {
    return GOTO_SHELL_VERSION
  }

  getChameleonWebVersion(): string {
    return getChameleonWebVersion()
  }

  getExtensions(): ExtensionMetadata[] {
    return getExtensionsManager()
      .getExtensions()
      .map(extension => extension.getMetadata())
  }
}
