import { filterForCompanion } from './companion-filter'
import { getExtensionConfigsWithOverrides } from './extension-config-overrides'
import { filterForIntegration } from './integration-filter'
import { filterForMobileIntegration } from './mobile-integration-filter'
import { filterExtensionsByPrerequisites } from './prerequisite-filter'
import { filterForStandaloneRoutes } from './standalone-filter'
import { filterForBlockedExtensions } from './blocked-extensions-filter'
import { getLocationPathname } from '../../common/dom-helpers'
import { type ExtensionConfig } from '../extension-config'

/**
 * Central function for applying any filters and overrides to the Extension Configs before Loading them
 */
export const applyExtensionConfigsTransformers = (extensionConfigs: readonly ExtensionConfig[]) => {
  const filters = [
    filterForStandaloneRoutes,
    filterExtensionsByPrerequisites,
    filterForCompanion,
    filterForIntegration,
    filterForMobileIntegration,
    filterForBlockedExtensions,
  ]
  const overrides = getExtensionConfigsOverrides()
  const transformers = [...filters, ...overrides]
  return transformers.reduce(
    (transformedExtensionConfigs, transform) => transform(transformedExtensionConfigs, getLocationPathname()),
    extensionConfigs,
  )
}

const getExtensionConfigsOverrides = () => [getExtensionConfigsWithOverrides]
