import { getActiveUrlPath } from '../../../common/dom-helpers'

/**
 * Check the location path and determine if it's matching with a link href
 * This must support the following path/link matching
 * Basic matching: /path match /path
 * Deep path matching: /deep/path match /path and /deep/path/nested match /path
 * QueryString matching: /path?foo=bar match /path?foo=bar but /path?foo=bar does not match /path. This only supports query string following path with exact matching order
 *
 */

export const linkIsActive = (href: string) => {
  const activeURL = getActiveUrlPath()

  if (activeURL.match(/^[^?#]*shell-settings/g)) {
    return false
  } else {
    const hrefCleanedUpForRegex = href.replace(/\/$/g, '').replace(/\?/g, '\\?')
    const regex = new RegExp(`(${hrefCleanedUpForRegex})(?:\\/|$|&)`, 'g')
    return regex.test(activeURL)
  }
}
