/* eslint-disable @typescript-eslint/no-explicit-any */

import type { EOMUnknown } from '../../eom'
import type {
  GlobalSearchAction,
  GlobalSearchCategory,
  GlobalSearchCategoryColumn,
  GlobalSearchEmptyState,
  GlobalSearchAvatar,
  GlobalSearchQuery,
  GlobalSearchShellIntegration,
} from '../global-search-integration'
import type { Settings } from '../settings-definition'
import type { ShellExtension, ShellExtensionCustomElements, ShellExtensionQuickActions } from '../shell-extension'

/* istanbul ignore next -> this is only a schema object so the functions are never called */
export const SHELL_EXTENSION_BASE_SCHEMA: ShellExtension = {
  id: '',
  initialize: async () => {},
  registerNamespaces: async () => {},
  getString: () => '',
  getComponentById: async () => null,
  getModules: () => [],
  getSettings: () => [],
  getNavigationItems: () => [],
  getMetadata: () => ({ id: '', name: '', version: '' }),
}

/* istanbul ignore next -> this is only a schema object so the functions are never called */
export const GLOBAL_SEARCH_SCHEMA: Omit<GlobalSearchShellIntegration, 'uid'> = {
  getGlobalSearchCategories: () => [],
  getGlobalSearchResultActions: () => [],
  getActionsForSearchString: () => [],
}

class SchemaError extends Error {
  constructor(
    public key: string,
    public actualType: string,
    public expectedType: string,
    public message: string = '',
  ) {
    super()
  }
}

/**
 * Asserts that an object implements the schema provided
 * @param object object to validate
 * @param schema schema used to validate the object
 */
export const assertSchema = <T extends object>(object: any, schema: T): void => {
  const keys = Object.keys(schema) as unknown as readonly (keyof T)[]
  keys.some(key => {
    if (typeof object?.[key] !== typeof schema[key]) {
      throw new SchemaError(key as string, typeof object?.[key], typeof schema[key], '')
    }
  })
}

/**
 * Validates if the object passed is a shell extension
 * @param extension extension to validate
 */
export const assertShellExtension = (extension: any): void => {
  try {
    assertSchema(extension, SHELL_EXTENSION_BASE_SCHEMA)
  } catch (e: any) {
    const message = `The extension is not compliant with the ShellExtension interface. The ${extension.id}.${e.key} type should be ${e.expectedType} instead of ${e.actualType}.`
    throw new SchemaError(e.key, e.actualType, e.expectedType, message)
  }
}

const isPropertyOfType = (property: any, types: readonly string[]): boolean => types.includes(typeof property)

const isSchemaSupported = <T extends object>(object: any, schema: T): object is T => {
  const keys = Object.keys(schema) as unknown as readonly (keyof T)[]
  return !keys.some(key => typeof object?.[key] !== typeof schema[key])
}

/**
 * Validates if an extension support custom elements interface
 * @param extension extension to validate
 * @returns true if extension support custom elements interface, false otherwise
 */
export const supportsCustomElements = (extension: ShellExtension): extension is ShellExtensionCustomElements => {
  /* istanbul ignore next -> this is only a schema object so the functions are never called */
  const schema: ShellExtensionCustomElements = {
    ...SHELL_EXTENSION_BASE_SCHEMA,
    registerCustomElements: () => {},
  }
  return isSchemaSupported(extension, schema)
}

/**
 * Validates if the extensions supports query interface / support interface functionnalities
 * @param extension extension to validate
 * @returns true if supported
 */
export const supportsEOM = (extension: ShellExtension): extension is ShellExtension & EOMUnknown => {
  /* istanbul ignore next -> this is only a schema object so the functions are never called */
  const schema: EOMUnknown = {
    queryInterface: () => {},
    supportsInterface: () => true,
  } as unknown as EOMUnknown
  return isSchemaSupported(extension, schema)
}

/**
 * Validates if an extension support quick actions interface
 * @param extension extension to validate
 */
export const supportsQuickActions = (extension: ShellExtension): boolean => {
  /* istanbul ignore next -> this is only a schema object so the functions are never called */
  const schema: ShellExtensionQuickActions = {
    ...SHELL_EXTENSION_BASE_SCHEMA,
    getQuickActions: () => [],
  }
  try {
    assertSchema(extension, schema)
    return true
  } catch (e: any) {
    return false
  }
}

/**
 * Check Settings type
 * @param settings
 * @returns
 */
export const isSettings = (settings: any): settings is Settings => {
  /* istanbul ignore next -> this is only a schema object so the functions are never called */
  const schemaSettings: Settings = {
    displayNameKey: '',
    componentId: '',
    route: '',
  }

  const keys = Object.keys(schemaSettings) as readonly (keyof Settings)[]
  return !keys.some(key => typeof settings?.[key] !== typeof schemaSettings[key])
}

/**
 * Validates if an integration support global search interface correctly
 * @param integration integration to validate
 */
export const assertGlobalSearch = (integration: GlobalSearchShellIntegration, extension: ShellExtension): void => {
  try {
    assertSchema(integration, GLOBAL_SEARCH_SCHEMA)
  } catch (e: any) {
    const message = `The implementation is not compliant with the GlobalSearchShellIntegration interface. The extension ${extension.id} implementation '${e.key}' type should be ${e.expectedType} instead of ${e.actualType}.`
    throw new SchemaError(e.key, e.actualType, e.expectedType, message)
  }
}

/**
 * Validate if an object is a global search action
 * @param object object to validate
 */
export const assertGlobalSearchAction = (object: any): void => {
  /* istanbul ignore next -> this is only a schema object so the functions are never called */
  const schema: GlobalSearchAction = {
    id: '',
    command: {
      commandName: '',
      namespace: '',
    },
    displayNameKey: '',
    icon: '',
  }
  try {
    assertSchema(object, schema)
  } catch (e: any) {
    const message = `The object is not compliant with the GlobalSearchAction interface. The ${object.id}.${e.key} type should be ${e.expectedType} instead of ${e.actualType}.`
    throw new SchemaError(e.key, e.actualType, e.expectedType, message)
  }
  try {
    assertSchema(object.command, schema.command)
  } catch (e: any) {
    const message = `The object is not compliant with the GlobalSearchAction interface. The ${object.id}.command.${e.key} type should be ${e.expectedType} instead of ${e.actualType}.`
    throw new SchemaError(e.key, e.actualType, e.expectedType, message)
  }
}

/**
 * Validate if an object is a global search category column
 * @param object object to validate
 */
export const assertGlobalSearchCategoryColumn = (object: any): void => {
  /* istanbul ignore next -> this is only a schema object so the functions are never called */
  const schema: Omit<GlobalSearchCategoryColumn, 'valueField'> = {
    displayNameKey: '',
    id: '',
  }
  try {
    assertSchema(object, schema)
  } catch (e: any) {
    const message = `The object is not compliant with the GlobalSearchCategoryColumn interface. The ${object.id}.${e.key} type should be ${e.expectedType} instead of ${e.actualType}.`
    throw new SchemaError(e.key, e.actualType, e.expectedType, message)
  }
  assertGlobalSearchValueField((object as GlobalSearchCategoryColumn).valueField, 'valueField')
}

/**
 * Validate if an object is a global search category avatar
 * @param object object to validate
 */
export const assertGlobalSearchAvatar = (object: any): void => {
  assertGlobalSearchValueField((object as GlobalSearchAvatar)?.userKey, 'userKey')
  assertGlobalSearchValueField((object as GlobalSearchAvatar)?.familyName, 'familyName')
  assertGlobalSearchValueField((object as GlobalSearchAvatar)?.givenName, 'givenName')
}

/**
 * Validate if a property is a global search value field
 * @param property property to validate
 */
export const assertGlobalSearchValueField = (property: any, key: string): void => {
  if (isPropertyOfType(property, ['string', 'function'])) {
    return
  }
  const e = {
    key,
    message: `The ${key} is not compliant with the GlobalSearchValueField interface. The property type should be string or function instead of ${typeof property}.`,
    expectedType: 'String or Function',
    actualType: typeof property,
  }
  throw new SchemaError(e.key, e.actualType, e.expectedType, e.message)
}

/**
 * Validate if a given object is a valid empty state object
 * @param object object to validate
 */
export const assertGlobalSearchEmptyState = (object: any): void => {
  /* istanbul ignore next -> this is only a schema object so the functions are never called */
  const schema: GlobalSearchEmptyState = {
    messageKey: '',
    actions: [],
  }
  try {
    assertSchema(object, schema)
  } catch (e: any) {
    const message = `The object is not compliant with the GlobalSearchEmptyState interface. The ${e.key} type should be ${e.expectedType} instead of ${e.actualType}.`
    throw new SchemaError(e.key, e.actualType, e.expectedType, message)
  }
  ;(object as GlobalSearchEmptyState).actions.forEach(action => assertGlobalSearchAction(action))
}

/**
 * Validates if the avatar info or icon is present and if so calls the function to assert their type
 * @param avatarFields object to validate
 * @param iconField property to validate
 */
export const assertIconOrAvatar = (avatarFields: any, iconField: any, categoryId: any): void => {
  if (avatarFields) {
    assertGlobalSearchAvatar(avatarFields)
  } else if (iconField) {
    assertGlobalSearchValueField(iconField, 'iconField')
  } else {
    const e = {
      key: 'avatarFields or iconField',
      message: `You must provide either the avatar information or an icon with your ${categoryId} GlobalSearchCategory object`,
      expectedType: 'GlobalSearchAvatar or GlobalSearchValueField',
      actualType: 'none',
    }
    throw new SchemaError(e.key, e.actualType, e.expectedType, e.message)
  }
}

/**
 * Validate if an object is a global search category
 * @param object object to validate
 */
export const assertGlobalSearchCategory = (object: any): void => {
  /* istanbul ignore next -> this is only a schema object so the functions are never called */
  const schema: Omit<
    GlobalSearchCategory,
    'titleField' | 'previewField' | 'avatarFields' | 'iconField' | 'emptyState'
  > = {
    id: '',
    application: '',
    columns: [],
    getResultDefaultAction: () => ({
      command: { commandName: '', namespace: '' },
      displayNameKey: '',
      icon: '',
      id: '',
    }),
    getQueryForSearchString: () =>
      ({ application: '', query: '', permissionParameters: { owner_id: [], subowner_id: [] } }) as GlobalSearchQuery,
  }
  const category: GlobalSearchCategory = object as GlobalSearchCategory
  try {
    assertSchema(category, schema)
  } catch (e: any) {
    const message = `The object is not compliant with the GlobalSearchCategory interface. The ${object.id}.${e.key} type should be ${e.expectedType} instead of ${e.actualType}.`
    throw new SchemaError(e.key, e.actualType, e.expectedType, message)
  }
  assertGlobalSearchValueField(category.previewField, 'previewField')
  assertGlobalSearchValueField(category.titleField, 'titleField')
  assertIconOrAvatar(category.avatarFields, category.iconField, category.id)
  category.columns?.forEach(column => assertGlobalSearchCategoryColumn(column))
  assertGlobalSearchEmptyState(category.emptyState)
}
