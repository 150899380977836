import { html } from 'lit-element'
import integrationTopbarStyles from './integration-topbar.styles.scss'
import { t } from '../../directives/translate'
import { SVG_SETTINGS_OUTLINED } from '@getgo/chameleon-icons'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { nothing } from 'lit-html'
import { isIntegrationMobile } from '../../core/integrations-helpers'
import { GotoBaseNavigationLinks } from '../navigation/base-navigation/base-navigation-links'

export class GoToIntegrationTopbar extends GotoBaseNavigationLinks {
  static readonly tagName = 'goto-integration-topbar'

  static get styles() {
    return integrationTopbarStyles
  }

  render() {
    return html`
      <div class="container">
        ${this.renderHorizontalNavRail()}
        <nav aria-label=${t('GoTo topbar')}>
          <ul>
            ${this.renderGlobalSearch()}${this.renderCallCardListItem()}${this.renderUserProfileMenu()}
          </ul>
        </nav>
      </div>
    `
  }

  async linksUpdated() {
    // hook after links are updated
  }

  private renderHorizontalNavRail() {
    return html` <goto-nav-bar .links=${this.links}></goto-nav-bar>`
  }

  private renderGlobalSearch() {
    return !isIntegrationMobile()
      ? html`<li><goto-global-search class="dynamic-width"></goto-global-search></li>`
      : nothing
  }

  private renderCallCardListItem() {
    return html`
      <li>
        <shell-topbar-call-card-container></shell-topbar-call-card-container>
      </li>
    `
  }

  private renderUserProfileMenu() {
    return html`
      <li>
        <chameleon-icon-button size="xlarge" id="user-menu-trigger"
          ><chameleon-svg> ${unsafeSVG(SVG_SETTINGS_OUTLINED)}</chameleon-svg></chameleon-icon-button
        >
      </li>
      <chameleon-popover-v2 trigger-id="user-menu-trigger" menu position="bottom-end" label=${t('User profile menu')}
        ><goto-integration-user-menu></goto-integration-user-menu
      ></chameleon-popover-v2>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToIntegrationTopbar.tagName]: GoToIntegrationTopbar
  }
}
