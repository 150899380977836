import {
  type EventBusEventPayload,
  type EventBusExternalInterfaceAdapter, type EventBusExternalInterfaceAdapterPayload,
  type EventBusExternalInterfaceAdapterPrivate, type ExternalInterfaceMessageEventPayload
} from "./types/event-bus-adapter-strategy";
import { type EventBusPrivate } from "../namespaces";
import { type ExternalInterfaceCallback } from "./external-interface";
import { type EventBusMessage, type EventBusMessagePayload } from "./messages/event-bus";
import { getExternalInterface } from "./external-interface-adapter";
import { onWindowUnload } from "../../common/helpers/window";
import { type EventsNamespaceDefinition } from "../../extensions";
import { getEventsToForwardToCompanion } from "../../core/integrations-helpers";

export class DefaultEventBusExternalInterfaceAdapter implements EventBusExternalInterfaceAdapterPrivate {

  private readonly strategies: EventBusExternalInterfaceAdapter[] = []

  constructor(eventBus: EventBusPrivate) {
    const handleExternalEvents: ExternalInterfaceCallback<EventBusMessagePayload> = ({ namespace, eventName, args, callerId }) => {
      const strategy = this.getStrategy({ namespace, eventName, eventPayload: args })
      const eventPayload = strategy.toEventBusEventPayload(args)
      eventBus.emitEvent(namespace, eventName, eventPayload, callerId)
    }

    getExternalInterface().addCallback('eventbus', handleExternalEvents)

    onWindowUnload(() => {
      getExternalInterface().removeCallback('eventbus', handleExternalEvents)
    })
  }

  public addStrategy(strategy: EventBusExternalInterfaceAdapter): void {
    this.strategies.push(strategy)
  }

  public removeStrategy(strategy: EventBusExternalInterfaceAdapter): void {
    const index = this.strategies.indexOf(strategy)
    if (index !== -1) {
      this.strategies.splice(index, 1)
    }
  }

  canHandleEvent(_namespace: string, _eventName: string, _eventPayload: EventBusEventPayload): boolean {
    return true
  }

  handleEvent(namespace: string, eventName: string, eventPayload: EventBusEventPayload): void {
    const strategy = this.getStrategy({ namespace, eventName, eventPayload })
    if (this.shouldSendToExternalInterface(strategy, namespace, eventName, eventPayload)) {
      const msg: EventBusMessage = {
        type: 'eventbus',
        payload: {
          namespace,
          eventName,
          args: strategy.toMessageEventPayload(eventPayload),
        },
      }
      getExternalInterface().send(msg)
    }
  }

  canForwardToCompanion(namespace: string, eventName: string, _eventPayload: EventBusEventPayload): boolean {
    const reducePredicate = (result: boolean, item: EventsNamespaceDefinition) => {
      if (item.namespace === namespace) {
        if (item.eventNames && item.eventNames.length) {
          return !!item.eventNames.find(itemEventName => itemEventName === eventName)
        }
        return true
      }
      return result
    }

    return getEventsToForwardToCompanion().reduce(reducePredicate, false)
  }

  canForwardToIntegration(_namespace: string, _eventName: string, _eventPayload: EventBusEventPayload): boolean {
    return true
  }

  toEventBusEventPayload(message: ExternalInterfaceMessageEventPayload): EventBusEventPayload {
    return message
  }

  toMessageEventPayload(event: EventBusEventPayload): ExternalInterfaceMessageEventPayload {
    return event
  }

  private getStrategy(payload: EventBusExternalInterfaceAdapterPayload): EventBusExternalInterfaceAdapter {
    return this.strategies.find(strategy => strategy.canHandleEvent(payload.namespace, payload.eventName, payload.eventPayload)) ?? this
  }

  private shouldSendToExternalInterface(strategy: EventBusExternalInterfaceAdapter, namespace: string, eventName: string, eventPayload: EventBusEventPayload): boolean {
    if (getExternalInterface().isCompanion) {
      return strategy.canForwardToIntegration(namespace, eventName, eventPayload)
    } else if (getExternalInterface().isIntegration) {
      return strategy.canForwardToCompanion(namespace, eventName, eventPayload)
    }
    return false
  }
}
