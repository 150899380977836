import { getNamespaces } from './namespace-manager'
import type { CommandMixin } from './commands'
import type { CommandDefinition } from './models'

/**
 * Retrieves a command for a namespace name and a command name
 * @param command Command to retrieve
 * @returns instance of command if it exists, undefined otherwise
 */
export const getCommand = (command: CommandDefinition): CommandMixin<readonly [], readonly []> | undefined => {
  const namespace = getNamespaces().retrieve(command.namespace)
  if (namespace) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (namespace.commands as any)[command.commandName] as CommandMixin<[], []>
  }
}

/**
 * Check if a given command is handled
 * @param command Command to validate
 * @returns true if command is handled, false otherwise
 */
export const isCommandHandled = (command: CommandDefinition): boolean => {
  const cmd = getCommand(command)
  if (cmd) {
    return cmd.isHandled()
  }
  return false
}

/**
 * Execute a given command with specified parameters
 * @param command Command to execute
 * @param params parameters to pass to the command
 */
export const executeCommand = (command: CommandDefinition, ...params: readonly unknown[]) => {
  const cmd = getCommand(command)
  if (cmd?.isHandled()) {
    // eslint-disable-next-line prefer-spread
    cmd.execute.apply(cmd, [...params])
  }
}
