import type { ExtensionConfig } from '@goto/shell'

export const extensionConfig: ExtensionConfig = {
  id: '@ucc/gtc-cc-topic-flag',
  url: new URL('/experiences/cc-topic-flag/goto-app-extension.js', location.origin),
  prerequisites: {
    ccFlags: 'cc-config.flags',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
  }
}

export default extensionConfig
