import { Environment, getCurrentEnvironment } from '@goto/shell'
import type { ExtensionConfig } from '@goto/shell'
import { getFeatureFlag } from '../helpers/shell-helpers'
import { createIntegrationConfigIfFlagEnabled } from '../helpers/integration-helpers'

enum Flags {
  MEETING_EXPERIENCE_VERSIONS = 'meeting-experience-app-version',
}

export const getMeetingEnvironmentUrl = () => {
  switch (getCurrentEnvironment()) {
    case Environment.ED:
      return 'https://apped1.g2m.test.expertcity.com/shell/'
    case Environment.RC:
      return 'https://apprc1.g2m.test.expertcity.com/shell/'
    case Environment.STAGING:
      return 'https://appstage.gotomeeting.com/shell/'
    case Environment.NIGHTLY:
      return 'https://app.gotomeeting.com/shell/beta/'
    case Environment.PROD:
    default:
      return 'https://app.gotomeeting.com/shell/'
  }
}

const integrationFlagSuffix = 'web-meeting'

const extensionConfig: ExtensionConfig = {
  id: '@getgo/web-meeting-shell-integration/extension',
  get url() {
    const version = getFeatureFlag(Flags.MEETING_EXPERIENCE_VERSIONS, { extension: '' })?.extension
    return new URL(version ? `${version}/extension.js` : 'extension.js', getMeetingEnvironmentUrl())
  },
  unguardedRoutes: ['/meeting', '/training', '/webinar', '/meet'],
  standaloneRoutes: ['^/meeting/', '^/training/', '^/webinar/', '^/meet/'],

  get integrationConfig() {
    return createIntegrationConfigIfFlagEnabled(integrationFlagSuffix)
  },
}

export default extensionConfig
