/**
 * Represents the context API, which provides access to various contextual information.
 */
export interface ContextApi {
  readonly pbx?: PbxContext // Represents the current PBX context.
  readonly account?: AccountContext // Represents the current account context.
  readonly currentApps?: readonly string[] // Represents the list of current apps.
  readonly productTrial?: ProductTrial // Represents the product trial information.
}

/**
 * Represents the PBX flags.
 */
export interface PbxFlags {
  readonly [key: string]: boolean // Represents the key-value pairs of PBX flags.
}

export interface PbxData {
  readonly accountKey: string // Represents the account key.
  readonly pbxId: string // Represents the PBX ID.
  readonly pbxUserId?: string // Represents the PBX user ID.
  readonly pbxFlags?: PbxFlags // Represents the PBX flags.
}

export interface JifApi {
  readonly data: {
    readonly tenants: {
      readonly pbxes: readonly Pbx[] // Represents the list of PBXes.
    }
  }
}

/**
 * Represents the PBX info parameters.
 */
export interface PbxInfoParams {
  readonly externalUserKey: string // Represents the external user key.
  readonly pbxId?: string // Represents the PBX ID.
  readonly pbxUserId?: string // Represents the PBX user ID.
}

/**
 * Represents the PBX.
 */
export interface Pbx {
  readonly id: string // Represents the PBX ID.
  readonly flags?: { readonly [key: string]: boolean } // Represents the key-value pairs of PBX flags.
  readonly extensions?: readonly Extension[] // Represents the list of extensions.
  readonly userInfo?: PbxUserInfo // Represents the PBX user information.
}

/**
 * Represents the PBX user information.
 */
export interface PbxUserInfo {
  readonly userId: string // Represents the PBX user ID.
}

/**
 * Represents the extension.
 */
export interface Extension {
  readonly id: string // Represents the extension ID.
  readonly number: string // Represents the extension number.
}

/**
 * Represents the principal.
 */
export interface Principal {
  readonly authorities: readonly unknown[] // Represents the authorities.
  readonly jrn: string // Represents the JRN.
  readonly partnerUsers: readonly unknown[] // Represents the partner users.
  readonly username: string // Represents the username.
  readonly users: readonly User[] // Represents the list of users.
}

/**
 * Represents the user.
 */
export interface User {
  readonly jrn: string // Represents the JRN.
  readonly state: string // Represents the state.
  readonly accountKey: string // Represents the account key.
  readonly pbxName: string // Represents the PBX name.
}

/**
 * Represents the product trial information.
 */
export interface ProductTrial {
  readonly free?: boolean // Represents whether the product is free.
  readonly trial?: boolean // Represents whether the product is on trial.
  readonly product?: string // Represents the product name.
  readonly endDate?: Date // Represents the end date of the trial.
  readonly active?: boolean // Represents whether the trial is active.
}

/**
 * Interface for PBX context.
 */
export interface PbxContextInterface {
  readonly id?: string
  readonly userId?: string
  readonly flags?: PbxFlags

  /**
   * Updates the PBX ID.
   * @param pbxId - The new PBX ID.
   */
  updateId(pbxId: string | undefined): void

  /**
   * Updates the PBX user ID.
   * @param pbxUserId - The new PBX user ID.
   */
  updateUserId(pbxUserId: string | undefined): void

  /**
   * Updates the PBX flags.
   * @param flags - The new PBX flags.
   */
  updateFlags(flags: PbxFlags | undefined): void
}

export class PbxContext implements PbxContextInterface {
  private _id: string | undefined
  private _userId: string | undefined
  private _flags: PbxFlags | undefined
  constructor(pbxId = '', pbxUserId = '', flags = {}) {
    this.updateId(pbxId)
    this.updateUserId(pbxUserId)
    this.updateFlags(flags)
  }

  public get id(): string | undefined {
    return this._id
  }

  public get userId(): string | undefined {
    return this._userId
  }

  public get flags(): PbxFlags | undefined {
    return this._flags
  }

  public updateId(pbxId: string | undefined) {
    this._id = pbxId
  }

  public updateUserId(pbxUserId: string | undefined) {
    this._userId = pbxUserId
  }

  public updateFlags(flags: PbxFlags | undefined) {
    this._flags = flags
  }
}

export class AccountContext {
  private _key: string | undefined
  constructor(key = '') {
    this.updateAccountKey(key)
  }

  public get key(): string | undefined {
    return this._key
  }

  public updateAccountKey(accountKey: string | undefined) {
    this._key = accountKey
  }
}
