/* istanbul ignore file */
import { singleSpaLitSettings } from '../../common'
import { html } from 'lit-element'
import { GOTO_SETTINGS_SELECTOR } from '../../common/container'

const lifecycles = singleSpaLitSettings({
  template: () => html`<goto-language-settings></goto-language-settings>`,
  domElement: GOTO_SETTINGS_SELECTOR,
})

export default lifecycles
